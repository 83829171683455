const SubmittedSkip = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 25"
    x="0px"
    y="0px"
    className="submitted-skip-icon"
    alt="Submitted Skip Icon"
  >
    <g>
      <rect x="2" y="11" width="4" height="4" rx="0.5" />
      <path d="M11.5,11h-3a.49971.49971,0,0,0-.5.5v3a.49971.49971,0,0,0,.5.5h3a.49971.49971,0,0,0,.5-.5v-3A.49971.49971,0,0,0,11.5,11ZM11,14H9V12h2Z" />
      <rect x="14" y="11" width="4" height="4" rx="0.5" />
      <path d="M15.5,10a.49842.49842,0,0,0,.35352-.14648l2-2a.5.5,0,0,0-.707-.707L15.96057,8.3324A5.99885,5.99885,0,0,0,4,9,.5.5,0,0,0,5,9a4.99069,4.99069,0,0,1,9.92047-.78656l-1.067-1.067a.5.5,0,0,0-.707.707l2,2A.49842.49842,0,0,0,15.5,10Z" />
    </g>
  </svg>
);

export default SubmittedSkip;
