import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Progress } from 'antd';
import CheckLogo from '../../assets/proctoring/CheckLogo';
import CrossLogo from '../../assets/proctoring/CrossLogo';
import Arrow from '../../assets/modal/Arrow';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import SliderParent from './SliderCaroussel/SliderCaroussel';
import Collapse from './Collapse/CollapseItem/CollapseItem';
import CheckIcon from '../../assets/modal/Check';
import Badge from './Badge/Badge';
import { getInvitedProfile, getProfile } from '../../API/services/alkymersService';
import {
  getScreeningByInstanceId,
  postSendEmailCustom,
  updateScreeningCandidatesBatch,
} from '../../API/services/screeningService';
import { ProctoringSummary } from './proctoringSummary';
import {
  getProctoringSummary,
  getSubmissionEventsDetails,
  getSubmissionWithEvents,
  getUserCameraSnapshots,
} from '../../API/services/alkycheatService';
import Spinner from '../../components/spinner/Spinner';
import './CantidateProctoringDetail.scss';
import CollapseWrapper from './Collapse/CollapseItem/CollapseWrapper';
import ProctoringCamera from '../../components/ProctoringCamera/ProctoringCamera';
import parseAnswerType from '../../utils/filters/answerTypeUtil';
import { getRiskLabel } from '../../utils/proctoring';
import { breadcrumbAlkymetricsEvaluation } from '../../constants/breadcrumb';
import { AnswerTypeEnum, TestGroupTypes } from '../../constants/enums';
import InvitedProfileData from '../../components/InvitedProfileData/InvitedProfileData';
import useTableBatchAction from '../../hooks/useTableBatchAction';
import { setInvitedProfile } from '../../redux/actions/profilesActions';
import { setStageCandidates, setSubjectEmail } from '../../redux/actions/screeningActions';
import EditEmailTemplate from '../../components/EditEmailTemplate/EditEmailTemplate';
import ProctoringOK from '../../assets/proctoring/ProctoringOK';
import ProctoringWarning from '../../assets/proctoring/ProctoringWarning';
import useTranslations from '../../hooks/useTranslations';

const getAnswerResult = (submission) => {
  const incorrectAnswer = t('PROCTORING_INCORRECT_ANSWER');
  const correctAnswer = t('PROCTORING_CORRECT_ANSWER');
  const partialCorrectAnswer = t('PROCTORING_PARTIAL_CORRECT_ANSWER');
  const neutralAnswer = t('PROCTORING_NEUTRAL_ANSWER');
  switch (submission?.answerType) {
    case 'CHECK':
    case 'RADIO':
      return (
        !isTestgroupListDetail &&
        (!submission?.isSubmission ||
        !submission.submittedOptions.length ||
        (submission?.answerType === AnswerTypeEnum.RADIO &&
          !submission?.hasGlobalScore &&
          submittedZeroPoints(
            submission?.acceptedOptions ?? [],
            submission?.submittedOptions ?? []
          ))
          ? neutralAnswer
          : submission?.acceptedOptions.length >= 1 &&
            (optionsMatch(
              submission?.acceptedOptions.filter((x) => x.weight > 0) ?? [],
              submission?.submittedOptions
            )
              ? optionsMatch(
                  [submission?.acceptedOptions.sort((a, b) => b.weight - a.weight)[0]],
                  submission?.submittedOptions
                )
                ? correctAnswer
                : partialCorrectAnswer
              : incorrectAnswer))
      );
    case AnswerTypeEnum.FILE:
      submission?.submittedFile ? correctAnswer : neutralAnswer;
    case AnswerTypeEnum.VIDEO:
      submission?.submittedVideo ? correctAnswer : neutralAnswer;
    case AnswerTypeEnum.TEXT_AI:
      partialCorrectAnswer;
    case AnswerTypeEnum.WRITE:
      !isTestgroupListDetail &&
      (!submission?.isSubmission ||
        (submission?.answerType === AnswerTypeEnum.WRITE && !submission?.submittedText))
        ? neutralAnswer
        : Number(submission?.ratio) > 0
        ? Number(submission?.ratio) === 1
          ? correctAnswer
          : partialCorrectAnswer
        : incorrectAnswer;
    case AnswerTypeEnum.CODE:
      showResult
        ? submission?.submittedCode?.length >= 1 && submission.ratio > 0
          ? correctAnswer
          : incorrectAnswer
        : null;
    case AnswerTypeEnum.CODE_AI:
      showResult
        ? submission?.submittedCode?.length >= 1 && submission.ratio > 0
          ? correctAnswer
          : incorrectAnswer
        : null;
    default:
      '';
  }
};
function CandidateProctoringDetail({ isInvitedProfile }) {
  const { recruitSessionId, profileId } = useParams();
  const [summary, setSummary] = useState([]);
  const [testGroups, setTestgroups] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [proctoringResult, setProctoringResult] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [screeningInstanceId, setScreeningInstanceId] = useState('');
  const [testgroupSubmissionsEvents, setTestgroupSubmissionsEvents] = useState({});
  const [imagesToShow, setImagesToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [submissionEventsListLoading, setSubmissionEventsListLoading] = useState(false);
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  const [submissionListDetail, setSubmissionListDetail] = useState({});
  const [imagesDeleted, setImagesDeleted] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    editMailVisible,
    setSelected,
    setEditMailVisible,
    invitationsList,
    confirmDeleteModalStage,
    startDeleteStageConfirmation,
    selected,
  } = useTableBatchAction();
  const { key } = useTranslations();
  const invitedProfile = useSelector((state) => state.profiles.invitedProfile);
  const profile = useSelector((state) => state.profiles.profile);
  const { template, stageCandidates, subjectEmail } = useSelector((state) => state.screenings);

  const getTestgroupSubmissionsEvents = async (
    testgroupId,
    testgroupName,
    instanceId = screeningInstanceId
  ) => {
    if (testgroupSubmissionsEvents[testgroupName]) {
      return '';
    }
    const submissionsWithEvents = await getSubmissionWithEvents(testgroupId, instanceId);
    setTestgroupSubmissionsEvents({
      ...testgroupSubmissionsEvents,
      [testgroupName]: submissionsWithEvents,
    });
  };

  const handleShow = (index) => {
    if (collapsedSelected.includes(index)) {
      setCollapsedSelected(collapsedSelected?.filter((selected) => selected !== index));
    } else {
      setCollapsedSelected([...collapsedSelected, index]);
    }
  };

  const changeFilter = async (id, name) => {
    setCollapsedSelected([]);
    setListLoading(true);
    const filters = testGroups.find((tg) => tg.id === id).tests.map((test) => test.id);
    setSelectedFilter({ ...selectedFilter, filters, testgroupId: id, testgroupName: name });
    await getTestgroupSubmissionsEvents(id, name, screeningInstanceId).then(() =>
      setListLoading(false)
    );
  };

  useEffect(() => {
    const getData = async () => {
      const invitedProfile = await getInvitedProfile(recruitSessionId, profileId);
      if (isInvitedProfile) {
        dispatch(setInvitedProfile(invitedProfile));
      } else {
        dispatch(getProfile(profileId));
      }
      const stageData = invitedProfile?.stages?.filter((stage) => Boolean(stage.invitation))[0];
      const { screeningInstanceId } = stageData.invitation;
      const recruitSessionStageId = stageData.recruitSessionStage.id;

      setScreeningInstanceId(screeningInstanceId);
      setImagesDeleted(stageData?.recruitSessionStage?.imagesDeleted ?? false);

      const summary = await getProctoringSummary(screeningInstanceId);
      const userSnapshots = await getUserCameraSnapshots(
        recruitSessionId,
        recruitSessionStageId,
        profileId,
        screeningInstanceId
      );

      const mappedSummary = Object.keys(summary).map((measure) => ({
        ...ProctoringSummary[measure],
        value: summary[measure]?.value,
        quantity: summary[measure]?.quantity,
      }));

      setSummary(mappedSummary);
      setImagesToShow(userSnapshots);
      getScreeningByInstanceId(screeningInstanceId).then(async (r) => {
        r.submissions.sort((a, b) => new Date(a.submittedAt) - new Date(b.submittedAt));
        setTestgroups(r.testGroups);
        setSubmissions(r.submissions);
        if (r.proctoringResult) {
          setProctoringResult(r.proctoringResult);
        }
        await getTestgroupSubmissionsEvents(
          r.testGroups[0].id,
          r.testGroups[0].name,
          screeningInstanceId
        );
        setListLoading(false);
        setSelectedFilter(() => ({
          testgroupId: r.testGroups[0].id,
          testgroupName: r.testGroups[0].name,
          filters: r.testGroups[0].tests.map((test) => test?.id),
        }));
        setLoading(false);
      });
    };
    getData();
  }, []);

  const getSubmissionEventList = async (id, userId, index) => {
    setSubmissionEventsListLoading({ loading: true, itemLoading: index });
    return getSubmissionEventsDetails(id, userId, screeningInstanceId).then((res) => {
      setSubmissionListDetail({ ...submissionListDetail, [id]: res });
      setSubmissionEventsListLoading({ loading: false });
    });
  };

  const addEventsSubmissions = (testgroupName, payload) => {
    setTestgroupSubmissionsEvents({
      ...testgroupSubmissionsEvents,
      [testgroupName]: [...testgroupSubmissionsEvents[testgroupName], ...payload],
    });
  };

  const addSubmissionListDetail = (submissionId, payload) => {
    setSubmissionListDetail({
      ...submissionListDetail,
      [submissionId]: {
        ...submissionListDetail[submissionId],
        collection: [...submissionListDetail[submissionId].collection, ...payload],
      },
    });
  };

  const updateStageSingleCandidate = async (newStatus, id) => {
    const body = {
      ids: [id],
      processStatus: newStatus,
    };
    if (newStatus && invitedProfile.stages[0].invitation.processStatus !== newStatus) {
      const updated = await updateScreeningCandidatesBatch(body);
      if (updated) {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_SUCCESS_SINGLE'),
          type: 'success',
        });
        dispatch(
          setStageCandidates({
            ...stageCandidates,
            collection: stageCandidates?.collection?.map((screening) => ({
              ...screening,
              ...(screening?.invitationId === id ? { processStatus: body.processStatus } : {}),
            })),
          })
        );
        const tempStages = invitedProfile.stages;
        tempStages[0].invitation.processStatus = newStatus;
        dispatch(setInvitedProfile({ ...invitedProfile, stages: tempStages }));
      } else {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_ERROR'),
          type: 'error',
        });
      }
    }
  };

  const handleSendEmail = async () => {
    let id = stageCandidates?.collection?.find(
      (candidate) => candidate.userId === invitedProfile.profile.id
    )?.invitationId;

    if (!id) id = Object.keys(selected)?.[0];

    const requestData = {
      invitationsIds: [id],
      emailType: 'CUSTOM_GENERIC',
      emailParams: {
        subject: subjectEmail.subjectEmail,
        html: template.template,
      },
      language: key,
    };
    try {
      await postSendEmailCustom(requestData, {});
      notification.open({
        message: t('INVITED_SINGLE_PROFILE_CORRECTLY'),
        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
      });
      setEditMailVisible(false);
      dispatch(setSubjectEmail({ subjectEmail: '' }));
    } catch (error) {
      notification.open({
        message: t('INVITATION_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };

  return loading || (!invitedProfile && !profile) ? (
    <Spinner show={loading} />
  ) : editMailVisible ? (
    <EditEmailTemplate
      handleSendRequest={handleSendEmail}
      goBack={() => setEditMailVisible(false)}
      viewInfo={false}
      emailDashboard
    />
  ) : (
    <div className="w-100 mx-auto proctoring-container">
      <div>
        <BackgroundTop
          title={t('INVITED_PROFILE_CANDIDATE_PROCTORING_BANNER_TITLE')}
          routes={[
            ...breadcrumbAlkymetricsEvaluation,
            {
              text: 'SCREENING_DASHBOARD_CAPSULE_TITLE',
              clickable: true,
              route: `/search/${recruitSessionId}`,
            },
            {
              text: 'INVITED_PROFILE_CANDIDATE_BANNER_TITLE',
              clickable: true,
              route: `/search/${recruitSessionId}/profiles/${profileId}`,
            },
          ]}
          className="position-relative"
          titleClassname="text-title"
        />
      </div>
      {loading ? (
        <div className="mt-5">
          <Spinner show />
        </div>
      ) : (
        <div className="d-flex justify-content-center p-2 w-100">
          <InvitedProfileData
            className="mr-2"
            profile={invitedProfile?.profile || profile}
            isInvitedProfile={isInvitedProfile}
            search={invitedProfile?.recruitSession}
            setSelected={setSelected}
            invitationsList={invitationsList}
            updateStageSingleCandidate={updateStageSingleCandidate}
            setEditMailVisible={setEditMailVisible}
            currentStage={
              invitedProfile?.stages?.filter((stage) => Boolean(stage.invitation)).reverse()[0]
                ?.recruitSessionStage
            }
            nextStage={
              invitedProfile?.stages?.filter((stage) => !stage.invitation)[0]?.recruitSessionStage
            }
            currentInvitation={
              invitedProfile?.stages?.filter((stage) => Boolean(stage.invitation)).reverse()[0]
                ?.invitation
            }
            deleteCandidate={startDeleteStageConfirmation}
          />

          <div className="d-flex flex-column detail-container-content bg-white b-radius-8 w-60 mx-2 ">
            <div className="d-flex w-100">
              <div className="d-flex flex-column w-100">
                <span className=" font-weight-600">{t('PROCTORING_DETAIL_REVIEW_TITLE')}</span>
                <span className=" text-xs my-2 mb-3">{t('PROCTORING_DETAIL_REVIEW_SUBTITLE')}</span>
                <div className="d-flex flex-column justify-content-around h-100">
                  {summary.map((measure) => (
                    <span
                      data-testid="block-summary-proctoring-detail"
                      key={measure.text}
                      className="my-2  text-xs font-weight-600 d-flex align-items-center"
                    >
                      <div className="proctoring-meausure-logo-container">{measure.logo}</div>
                      {t(measure.text)}
                      <div className="d-flex align-items-center">
                        <div className="proctoring-meausure-logo-container ml-2">
                          {measure.value ? <CheckLogo /> : <CrossLogo />}
                        </div>
                        {!measure.value && (
                          <div
                            data-testid="incidences-container"
                            className="incidences-quantity-container d-flex justify-content-center align-items-center "
                          >
                            {measure.quantity}
                          </div>
                        )}
                      </div>
                    </span>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-column">
                <button
                  onClick={() => history.goBack()}
                  type="button"
                  className="mb-3 ml-auto align-self-end px-4 py-1 rounded-pill  text-white text-xs font-weight-bold border-0 outline-none bg-maya-blue"
                >
                  {t('PROCTORING_DETAIL_GO_BACK')}
                </button>
                <ProctoringCamera imagesToShow={imagesToShow} imagesDeleted={imagesDeleted} />
              </div>
            </div>
            {proctoringResult ? (
              <div className="d-flex flex-column w-100 mt-3">
                <span className="font-weight-600">{t('PROCTORING_DETAIL_AI_ANALYSIS')}</span>
                <div className="d-flex mt-4">
                  <div className="d-flex flex-column align-items-center">
                    <Progress
                      type="circle"
                      strokeWidth={4}
                      strokeColor={{
                        '30%': '#4987BE',
                        '100%': '#9BF558',
                      }}
                      className="mx-2"
                      width={80}
                      percent={proctoringResult?.score * 100}
                      format={() => (
                        <div className="d-flex flex-column">
                          <span className="font-weight-bold">{proctoringResult?.score * 100}%</span>
                        </div>
                      )}
                    />
                    <span className="font-weight-bold text-sm">
                      {t(getRiskLabel(proctoringResult?.score))}
                    </span>
                  </div>
                  <span className="mx-4">{proctoringResult?.explanation}</span>
                </div>
              </div>
            ) : null}

            <div className="d-flex flex-column w-100 mt-3">
              <span className=" font-weight-600">{t('PROCTORING_DETAIL_SECTIONS_TITLE')}</span>
              <div className="mt-1">
                <SliderParent
                  selectFilter={changeFilter}
                  selectedFilter={selectedFilter?.testgroupId}
                  data={testGroups.map(({ name, id }) => ({ name, id }))}
                />
              </div>
              <div className="pr-2">
                {Boolean(submissions.length) && (
                  <>
                    {listLoading ? (
                      <Spinner show />
                    ) : (
                      <>
                        {submissions
                          .filter((submission) =>
                            selectedFilter?.filters?.includes(submission?.testId)
                          )
                          .map((submission, index) => {
                            const hasEventsRegistered = testgroupSubmissionsEvents[
                              selectedFilter.testgroupName
                            ]?.includes(submission.id);

                            const selectedTestGroup = testGroups.find((tg) =>
                              tg.tests.find((test) => test.id === submission.testId)
                            );

                            const selectedTest = selectedTestGroup.tests.find(
                              (test) => test.id === submission.testId
                            );

                            return (
                              <Collapse
                                isProctoringDetail
                                collapsedSelected={collapsedSelected}
                                index={index}
                                key={submission.id}
                                classCustom={
                                  hasEventsRegistered
                                    ? 'item-container-with-proctoring-events'
                                    : undefined
                                }
                                options={{
                                  header: (
                                    <div
                                      data-testid={`header-collapse-${submission.id}`}
                                      className="d-flex w-100 justify-content-between align-items-center"
                                    >
                                      <span className="mr-1 text-truncate m-0  font-weight-600 proctoring-gray-text text-xs">
                                        {collapsedSelected.includes(index)
                                          ? parseAnswerType(selectedTest.answerType)
                                          : selectedTest?.text
                                              ?.replace(/<[^>]+>/g, '')
                                              .replace(new RegExp('&lt;', 'g'), '<')
                                              .replace(new RegExp('&gt;', 'g'), '>')}
                                      </span>
                                      <div
                                        data-testid={`div-badge-${submission.id}`}
                                        className="d-flex"
                                      >
                                        {submissionEventsListLoading.loading &&
                                        submissionEventsListLoading.itemLoading === index ? (
                                          <div
                                            className="mx-1 spinner-border text-blue-principal spinner-border-sm spinner-loading-list mr-3"
                                            role="status"
                                          />
                                        ) : null}
                                        <Badge
                                          variant={
                                            submission?.score === 0
                                              ? 'badge-warning'
                                              : 'badge-success'
                                          }
                                          text={
                                            submission?.score === 0
                                              ? t('PROCTORING_INCORRECT_ANSWER')
                                              : t('PROCTORING_CORRECT_ANSWER')
                                          }
                                        />
                                        <div className="d-inline-block arrow-button-container">
                                          {hasEventsRegistered ? (
                                            <button
                                              data-testid={`button-arrow-collapse-${submission.id}`}
                                              className={`${
                                                collapsedSelected.includes(index)
                                                  ? 'arrow-dropdown-modal-collapsed'
                                                  : 'arrow-dropdown-modal-uncollapsed'
                                              } border-0 bg-transparent mx-auto cursor-pointer`}
                                              type="button"
                                              onClick={() => {
                                                if (
                                                  !submissionListDetail[submission.id] &&
                                                  !submissionEventsListLoading.loading
                                                ) {
                                                  getSubmissionEventList(
                                                    submission.id,
                                                    profileId,
                                                    index
                                                  ).then(() => handleShow(index));
                                                } else {
                                                  handleShow(index);
                                                }
                                              }}
                                            >
                                              <Arrow />
                                            </button>
                                          ) : null}
                                        </div>
                                        {selectedTestGroup.type !== TestGroupTypes.QUALITATIVE &&
                                          selectedTest?.answerType !== AnswerTypeEnum.VIDEO && (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>
                                                  {!hasEventsRegistered
                                                    ? t('EVENTS_OK')
                                                    : t('EVENTS_NG')}
                                                </Tooltip>
                                              }
                                            >
                                              <div data-testid={`div-icon-${submission.id}`}>
                                                {!hasEventsRegistered ? (
                                                  <ProctoringOK style={{ transform: 'scale(4)' }} />
                                                ) : (
                                                  <ProctoringWarning
                                                    style={{ transform: 'scale(5)' }}
                                                  />
                                                )}
                                              </div>
                                            </OverlayTrigger>
                                          )}
                                      </div>
                                    </div>
                                  ),
                                  content: (
                                    <div
                                      data-testid="content-collapse"
                                      className="content-container p-3 b-radius-8 border-maya-blue"
                                    >
                                      <div className="w-100 d-flex flex-column">
                                        <>
                                          <span
                                            dangerouslySetInnerHTML={{ __html: selectedTest?.text }}
                                            className=" text-xs font-weight-500 item-detail-title mb-3"
                                          />
                                          <div className="d-flex w-100 mb-2">
                                            <span className="timestamp-container font-weight-600  item-detail-title">
                                              {t('PROCTORING_DETAIL_ITEM_TIMESTAMP_TITLE')}
                                            </span>
                                            <span className="notification-container font-weight-600  item-detail-title">
                                              {t('PROCTORING_DETAIL_ITEM_NOTIFICATION_TITLE')}
                                            </span>
                                          </div>
                                          <CollapseWrapper
                                            testGroupName={selectedFilter.testgroupName}
                                            addEventsSubmissions={addEventsSubmissions}
                                            addSubmissionListDetail={addSubmissionListDetail}
                                            screeningInstanceId={screeningInstanceId}
                                            submissionId={submission.id}
                                            userId={profileId}
                                            listDate={submissionListDetail[submission.id]}
                                          />
                                        </>
                                      </div>
                                    </div>
                                  ),
                                }}
                              />
                            );
                          })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CandidateProctoringDetail;
