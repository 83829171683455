import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { t } from '../../i18n/i18n';
import { getProfileCommunications, getProfileEvents } from '../../API/services/alkymersService';
import CheckIcon from '../../assets/modal/Check';
import FailedIcon from '../../assets/modal/Failed';
import './InvitedProfileCommunications.scss';
import Collapsed from '../Collapsed/Collapsed';
import { nanoid } from 'nanoid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillInfoCircle, AiOutlineFieldTime } from 'react-icons/ai';
import { CommunicationStatus } from '../../constants/enums';
import moment from 'moment';
import { userUtcOffset } from '../../utils/calendarUtils';
import MinusWarning from '../../assets/modal/MinusWarning';
import { isNullOrUndefined } from '../../utils/typesUtils';

const InvitedProfileCommunications = ({ isInvitedProfile }) => {
  const [communications, setCommunications] = useState([]);
  const [loading, setLoading] = useState(true);
  const invitationId = useSelector(
    (state) => state?.profiles?.uniqueProfileData?.data?.[0]?.invitation?.id
  );
  const invitationData = useSelector(
    (state) => state?.profiles?.uniqueProfileData?.data?.[0]?.invitation
  );
  const recruitSessionStage = useSelector(
    (state) => state?.profiles?.uniqueProfileData?.data?.[0]?.recruitSessionStage
  );
  const [otherCommunications, setOtherCommunications] = useState([]);
  const [updateText, setUpdateText] = useState('');

  useEffect(() => {
    const updateTimesUTC = [0, 6, 12, 18];
    const now = new Date();
    const nowLocal = new Date();

    let lastUpdate = null;
    let nextUpdate = null;

    for (let hour of updateTimesUTC) {
      const updateTimeUTC = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hour)
      );
      const updateTimeLocal = new Date(updateTimeUTC);

      if (updateTimeLocal <= nowLocal) {
        lastUpdate = updateTimeLocal;
      } else if (!nextUpdate) {
        nextUpdate = updateTimeLocal;
      }
    }

    if (!nextUpdate) {
      const nextDay = new Date();
      nextDay.setUTCDate(nextDay.getUTCDate() + 1);
      nextUpdate = new Date(
        Date.UTC(
          nextDay.getUTCFullYear(),
          nextDay.getUTCMonth(),
          nextDay.getUTCDate(),
          updateTimesUTC[0]
        )
      );
      nextUpdate = new Date(nextUpdate);
    }

    const lastUpdateDate = lastUpdate.getDate();
    const nowDate = nowLocal.getDate();
    const lastUpdateLabel = lastUpdateDate === nowDate ? t('TODAY') : t('YESTERDAY');

    const formatTime = (date) =>
      date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });

    setUpdateText(
      t('UPDATED_NOTE_COMMENTS')
        .replaceAll('{{todayOrYesterday}}', lastUpdateLabel.toLowerCase())
        .replaceAll('{{lastUpdate}}', formatTime(lastUpdate))
        .replaceAll('{{nextUpdate}}', formatTime(nextUpdate))
    );
  }, []);

  useEffect(() => {
    if (!isInvitedProfile) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const fetchCommunications = async () => {
      try {
        const [communications, events] = await Promise.all([
          getProfileCommunications(invitationData?.profileId, recruitSessionStage?.id),
          getProfileEvents(invitationData?.profileId, recruitSessionStage?.id),
        ]);

        const eventsTranslated =
          events?.length >= 1
            ? events.map((event) => {
                return {
                  ...event,
                  subjectEmail: t(`EVENT_SUBJECT_${event.typeEmail}`),
                };
              })
            : [];

        const combinedData = [...(communications || []), ...(eventsTranslated || [])]
          .map((item) => {
            let localSendDate;

            if (moment(item.sendDate, moment.ISO_8601, true).isValid()) {
              localSendDate = moment(item.sendDate).utc(userUtcOffset());
            } else {
              localSendDate = null;
            }

            return {
              ...item,
              localSendDate,
            };
          })
          .sort((a, b) => {
            const dateA = new Date(a.sendDate).getTime() || 0;
            const dateB = new Date(b.sendDate).getTime() || 0;
            return dateB - dateA;
          });

        setCommunications(combinedData.filter((com) => com?.currentRecruitSessionStage));
        setOtherCommunications(combinedData.filter((com) => !com?.currentRecruitSessionStage));
      } catch (error) {
        console.error('Error fetching communications and events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommunications();
  }, [invitationId]);

  /*useEffect(() => {
    const fetchOtherCommunications = async () => {
      if (profileInvitationsAndChallenges?.invitations?.length) {
        setLoading(true);
        try {
          const allCommunications = await Promise.all(
            profileInvitationsAndChallenges.invitations.map(async (invitation) => {
              if (invitation.id !== invitationId) {
                const communications = await getCommunicationsAssociated(invitation.id);
                if (communications?.length > 0) {
                  return {
                    communications,
                    screeningName: invitation.screeningName,
                  };
                }
              }
              return null;
            })
          );
          const filteredCommunications = allCommunications.filter((item) => item !== null);
          setOtherCommunications(filteredCommunications);
        } catch (error) {
          console.error('Error fetching other communications:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOtherCommunications();
  }, [invitationId]);*/
  const conditionIcon = (timeCondition = false, errorOrCancel, condition) =>
    timeCondition ? (
      <AiOutlineFieldTime size={'2em'} />
    ) : errorOrCancel ? (
      <FailedIcon />
    ) : condition ? (
      <CheckIcon />
    ) : (
      <MinusWarning />
    );
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  return loading ? (
    <Spinner show />
  ) : (
    <div className="mt-4">
      {Boolean(!communications?.length) && Boolean(!otherCommunications?.length) ? (
        <EmptyComponent
          className="p-4"
          title={t('INVITED_PROFILE_COMMUNICATIONS_EMPTY_STATE_TITLE')}
          subtitleClassname="text-center"
        />
      ) : (
        <>
          {Boolean(communications?.length) ? (
            <>
              <CommunicationsColumn classNames="d-none d-sm-flex row no-gutters justify-content-center align-items-center text-xs px-4" />

              <div className="px-4">
                {communications?.map((communication, i) => (
                  <CommunicationItem
                    communication={communication}
                    i={i}
                    conditionIcon={conditionIcon}
                  />
                ))}
              </div>
            </>
          ) : (
            <EmptyComponent
              className="p-4"
              title={t('INVITED_PROFILE_COMMUNICATIONS_EMPTY_STATE_TITLE_THIS_EVALUATION')}
              subtitleClassname="text-center"
            />
          )}
          {Boolean(otherCommunications?.length) && (
            <div className="mt-4">
              {[
                {
                  title: t('COMMUNICATIONS_FROM_OTHER_EVALUATIONS'),
                  content: (
                    <div className="pb-4">
                      <CommunicationsColumn classNames="d-none d-sm-flex row no-gutters justify-content-center align-items-center text-xs" />
                      {otherCommunications?.map((item, i) => (
                        <div>
                          <span className="font-weight-700 text-md color-blue-principal text-underline mb-2">
                            {item.screeningName}
                          </span>
                          <CommunicationItem
                            communication={item}
                            i={i}
                            conditionIcon={conditionIcon}
                          />
                        </div>
                      ))}
                    </div>
                  ),
                  isVisible: true,
                  collapsedEnabled: true,
                },
              ].map((item, index) => (
                <Collapsed
                  collapsedSelected={collapsedSelected}
                  setCollapsedSelected={setCollapsedSelected}
                  index={98}
                  options={item}
                  collapsedEnabled={item.collapsedEnabled}
                  containerClassname={'py-0 my-0 px-4'}
                  titleClassnames={
                    'justify-content-end align-items-center gap-1 font-weight-400 text-xs text-muted py-2 '
                  }
                  backgroundColor={'bg-blue-light'}
                  maxHeight="20rem"
                />
              ))}
            </div>
          )}
          <span className="mx-4 text-xxs note-updated pb-2">{updateText}</span>
        </>
      )}
    </div>
  );
};

export default InvitedProfileCommunications;

const CommunicationItem = ({ communication, i, conditionIcon }) => (
  <div
    className={`row no-gutters justify-content-center align-items-center font-weight-600 mt-2 p-2  text-xs communication-container ${
      communication?.status === CommunicationStatus.DELIVERED
        ? 'communication-delivered-background'
        : [CommunicationStatus.CANCELED_WITH_ERROR, CommunicationStatus.CANCELED].includes(
            communication?.status
          )
        ? 'communication-canceled-background'
        : communication?.status === CommunicationStatus.ERROR
        ? 'communication-error-background'
        : ''
    }`}
    key={nanoid()}
  >
    <div className="col-12 col-sm-8">
      <div className="row no-gutters justify-content-center align-items-center ">
        <div className="col-12 col-sm-4">
          <span className="d-flex font-weight-600  text-xs">
            <span className="d-sm-none d-flex pr-2 ">
              {t('INVITED_PROFILE_COMMUNICATIONS_DATE')}:
            </span>
            {format(new Date(communication.localSendDate), 'yyyy/MM/dd HH:mm').concat(' hs')}
          </span>
        </div>
        <div className="col-5 col-sm-2">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip align={'center'} placement="top">
                <span className=" text-xs font-weight-700 text-center w-100">
                  {t(`COMMUNICATION_TYPE_${communication?.typeEmail}`)}
                </span>
              </Tooltip>
            }
          >
            <AiFillInfoCircle color="silver" size={'1.5em'} />
          </OverlayTrigger>
        </div>
        <div className="col-12 col-sm-6">
          <span className="d-flex font-weight-600  text-xs">
            <span className="d-sm-none d-flex pr-2">
              {t('INVITED_PROFILE_COMMUNICATIONS_SUBJECT')}:
            </span>
            {communication.subjectEmail}
          </span>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4 mt-2 mt-sm-0">
      <div className="row no-gutters justify-content-center align-items-center text-xs text-center">
        <div className="col-5">
          <span className="d-sm-none d-block mb-1 ">
            {t('INVITED_PROFILE_COMMUNICATIONS_RECEIVED')}
          </span>
          <OverlayTrigger
            key={`1- ${communication?.id}`}
            placement="top"
            overlay={
              <Tooltip id={`tooltip1-${communication?.id}`}>{`${
                communication?.status ? communication?.status : t('WAITING')
              }${
                communication?.status === CommunicationStatus.ERROR
                  ? ': '.concat(communication?.errorReason)
                  : ''
              }`}</Tooltip>
            }
          >
            <div>
              {conditionIcon(
                new Date(communication?.localSendDate) >= new Date(),
                communication?.status === CommunicationStatus.ERROR ||
                  [CommunicationStatus.CANCELED_WITH_ERROR, CommunicationStatus.CANCELED].includes(
                    communication?.status
                  ),
                communication.status === CommunicationStatus.DELIVERED
              )}
            </div>
          </OverlayTrigger>
        </div>
        <div className="col-4">
          <span className="d-sm-none d-block mb-1">
            {t('INVITED_PROFILE_COMMUNICATIONS_OPENED')}
          </span>
          <OverlayTrigger
            key={`2- ${communication?.id}`}
            placement="top"
            overlay={
              <Tooltip id={`tooltip2-${communication?.id}`}>{`${t('COMUNICATION_OPENS')} ${
                communication.opensCount
              }`}</Tooltip>
            }
          >
            <div>
              {conditionIcon(
                new Date(communication?.localSendDate) >= new Date(),
                communication?.status === CommunicationStatus.ERROR ||
                  [CommunicationStatus.CANCELED_WITH_ERROR, CommunicationStatus.CANCELED].includes(
                    communication?.status
                  ),
                communication.opensCount > 0
              )}
            </div>
          </OverlayTrigger>
        </div>
        <div className="col-3">
          <span className="d-sm-none d-block mb-1">
            {t('INVITED_PROFILE_COMMUNICATIONS_CLICKED')}
          </span>
          <OverlayTrigger
            key={`3- ${communication?.id}`}
            placement="top"
            overlay={
              <Tooltip id={`tooltip3-${communication?.id}`}>{`${t('COMUNICATION_CLICKS')} ${
                communication.clicksCount
              }`}</Tooltip>
            }
          >
            <div>
              {conditionIcon(
                new Date(communication?.localSendDate) >= new Date(),
                communication?.status === CommunicationStatus.ERROR ||
                  [CommunicationStatus.CANCELED_WITH_ERROR, CommunicationStatus.CANCELED].includes(
                    communication?.status
                  ),
                communication.clicksCount > 0
              )}
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  </div>
);

const CommunicationsColumn = ({ classNames }) => (
  <div className={classNames}>
    <div className="col-12 col-sm-8">
      <div className="row no-gutters justify-content-center align-items-center text-xs px-1">
        <div className="col-12 col-sm-4 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_DATE')}
        </div>
        <div className="col-12 col-sm-2 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_TYPE_EMAIL')}
        </div>
        <div className="col-12 col-sm-6 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_SUBJECT')}
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4">
      <div className="row no-gutters justify-content-center align-items-center text-xs text-center">
        <div className="col col-sm-5 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_RECEIVED')}
        </div>
        <div className="col col-sm-4 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_OPENED')}
        </div>
        <div className="col col-sm-3 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_CLICKED')}
        </div>
      </div>
    </div>
  </div>
);
