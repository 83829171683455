import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification, Radio, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import BackgroundTop from '../BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import arrowRight from '../../assets/sentScreening/arrowRightWhite.svg';
import { getLastEmail, saveTemplateEmail } from '../../API/services/alkymersService';
import { setSubjectEmail, setTemplate } from '../../redux/actions/screeningActions';
import { templateDefault } from '../../constants/emailTemplate';
import 'react-quill/dist/quill.snow.css';
import './EditEmailTemplate.scss';
import useCreateSearch from '../../hooks/useCreateSearch';
import ModalCustom from '../ModalCustom/ModalCustom';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import { Window } from '../../utils/window';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';
import SafeContainer from '../SafeContainer/SafeContainer';
import EmailTagsInput from './EmailTagsInput';
import useTranslations from '../../hooks/useTranslations';
import SlateEditor from '../SafeContainer/SlateEditor';
import CountryFlag from '../CountryFlag/CountryFlag';
import { languagesList } from '../DropdownLanguage/DropdownLanguage';
import EditPencilIcon from '../../assets/common/EditPencilIcon';
import Cross from '../../assets/common/Cross';
import CheckIcon from '../../assets/login/CheckIcon';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;
const defaultKeys = ['{{firstName}}', '{{lastName}}', '{{companyName}}', '{{email}}'];
const EditCustomEmailTemplate = ({
  goBack,
  handleSendRequest,
  viewInfo = true,
  emailDashboard = false,
  emails = [],
  customTemplate = null,
  newCustomEmail = false,
}) => {
  const { template, newSearch, subjectEmail } = useSelector((state) => state.screenings);
  const defaultCustomEmail = useMemo(() => customTemplate || template, [customTemplate, template]);
  const { user, currentWorkspace, currentRole } = useSelector((state) => state.auth);
  const { key } = useTranslations();
  const [selectedLang, setSelectedLang] = useState(key);
  const [disabledTitleBtn, setDisabledTitleBtn] = useState(true);
  const [newTitle, setNewTitle] = useState(
    customTemplate?.translations?.[selectedLang]?.[customTemplate?.title] ??
      t(customTemplate?.title)
  );
  const [oldTitle, setOldTitle] = useState('');
  const [selectLanguages, setSelectLanguages] = useState(Object.keys(customTemplate?.translations));

  const { step } = useCreateSearch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState('');
  const translateHTML = (html, lang = 'es') => {
    let translation = defaultCustomEmail?.translations[lang];
    if (!translation) {
      translation =
        defaultCustomEmail?.translations[Object.keys(defaultCustomEmail?.translations)[0]];
    }
    for (const [key, value] of Object.entries(translation || {})) {
      html = html?.replace(new RegExp(`{{${key}}}`, 'g'), value);
    }
    return html;
  };
  const translateEmail = (LABEL, lang = 'es') =>
    (defaultCustomEmail?.translations[lang] && defaultCustomEmail?.translations[lang][LABEL]) ||
    LABEL;

  const [htmlTranslate, setHtmlTranslate] = useState({
    es: {
      HTML: translateHTML(customTemplate?.html, 'es'),
      SUBJECT: translateEmail(customTemplate?.subject, 'es'),
    },
    en: {
      HTML: translateHTML(customTemplate?.html, 'en'),
      SUBJECT: translateEmail(customTemplate?.subject, 'en'),
    },
    pt: {
      HTML: translateHTML(customTemplate?.html, 'pt'),
      SUBJECT: translateEmail(customTemplate?.subject, 'pt'),
    },
  });
  const [subject, setSubject] = useState(subjectEmail.subjectEmail);
  const [buttonText, setButtonText] = useState(t('GO_TO_PLATFORM'));
  const regex = new RegExp(/\{(.+?)\}/g);
  const [result, setResult] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [emailsToSend, setEmailsToSend] = useState(emails);

  const { Option } = Select;

  const mapObj = {
    '{btn-url}': `<a class="custom-button">Ir a campus<span>→</span></a>`,
    '{{firstName}}': t('EMAIL_TO_SEND_FIRSTNAME'),
    '{{companyName}}': t('ALKEMY'),
    '{{lastName}}': t('EMAIL_TO_SEND_LASTNAME'),
    '{{score}}': t('EMAIL_TO_SEND_SCORE'),
    '{{email}}': 'alkemy@test.com',
    ...(customTemplate
      ? Object.fromEntries(
          Object.entries(customTemplate?.config?.variables ?? {})?.map(([key, value]) => [
            `{{${key}}}`,
            value,
          ])
        )
      : {}),
  };
  useEffect(() => {
    const url = history.location.pathname;
    if (Window() && url !== '/create-template') {
      window.onpopstate = () => {
        history.goForward();
      };
    }
    return () => {
      if (Window()) {
        window.onpopstate = () => {};
      }
    };
  }, []);

  useEffect(() => {
    const html = translateHTML(defaultCustomEmail?.html, selectedLang);
    const newsubject = translateEmail(defaultCustomEmail?.subject, selectedLang);
    setSubject(newsubject);
    setValue(html);
    setHtmlTranslate({ ...htmlTranslate, [selectedLang]: { HTML: html, SUBJECT: newsubject } });

    dispatch(
      setSubjectEmail({
        subjectEmail: defaultCustomEmail?.translated?.subject
          ? defaultCustomEmail?.translated?.subject
          : '',
      })
    );
  }, []);

  useEffect(() => {
    const allKeys = Array.from(
      new Set([
        ...defaultKeys,
        ...Object.keys(customTemplate?.config?.variables ?? {})?.map((key) => `{{${key}}}`),
      ])
    );
    const regexPattern = allKeys
      .map((key) => key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) // Escapa caracteres especiales
      .join('|');
    const dynamicRegex = new RegExp(regexPattern, 'g');
    document.getElementById('divCustomPreview').innerHTML = translateHTML(
      value?.replace(dynamicRegex, (matched) => mapObj[matched]),
      selectedLang
    );

    if (
      value.match(regex) !== null &&
      !!value.match(regex).length &&
      JSON.stringify(value.match(regex)) !== JSON.stringify(result)
    )
      setResult(value.match(regex));
  }, [value]);

  useEffect(() => {
    const html =
      htmlTranslate[selectedLang]?.HTML || translateHTML(defaultCustomEmail?.html, selectedLang);
    const newsubject =
      htmlTranslate[selectedLang]?.SUBJECT ||
      translateEmail(defaultCustomEmail?.subject, selectedLang);

    setValue(html);
    setSubject(newsubject);
    setHtmlTranslate({ ...htmlTranslate, [selectedLang]: { HTML: html, SUBJECT: newsubject } });
  }, [selectedLang]);

  const onSelectLanguages = (value = []) => {
    if (!value.length) {
      notification.open({
        message: t('No se puede dejar vacío el selector de idomas'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
      return;
    }
    const htmlDefault =
      htmlTranslate[selectedLang]?.HTML || translateHTML(defaultCustomEmail?.html, selectedLang);
    const newsubjectDefault =
      htmlTranslate[selectedLang]?.SUBJECT ||
      translateEmail(defaultCustomEmail?.subject, selectedLang);
    const newHtmlTranslate = htmlTranslate;
    value?.map((value) => {
      let html = htmlDefault;
      let newSubject = newsubjectDefault;
      if (htmlTranslate[value]?.HTML) {
        html = htmlTranslate[value]?.HTML;
      }
      if (htmlTranslate[value]?.SUBJECT) {
        newSubject = htmlTranslate[value]?.SUBJECT;
      }
      newHtmlTranslate[value].SUBJECT = newSubject;
      newHtmlTranslate[value].HTML = html;
    });
    setHtmlTranslate(newHtmlTranslate);
    setSelectLanguages(value);
    if (!value.includes(selectedLang)) {
      setSelectedLang(value[0]);
    }
  };

  useEffect(() => {
    const allKeys = Array.from(
      new Set([
        ...defaultKeys,
        ...Object.keys(customTemplate?.config?.variables ?? {})?.map((key) => `{{${key}}}`),
      ])
    );

    if (result.length && !result?.every((string) => allKeys.includes(string))) {
      setError(true);
    } else {
      setError(false);
    }
  }, [result]);

  const handleCustomEmail = async () => {
    TrackEvent('search-send-email');
    const htmlRequest = {};
    selectLanguages.forEach((lang) => {
      htmlRequest[lang] = htmlTranslate[lang];
    });
    if (subject !== '') {
      setShowModal(true);
    } else {
      notification.open({
        message: t('SUBJECT_EMAIL_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };
  const handleSaveCustomEmail = async () => {
    TrackEvent('search-save-email');
    const htmlRequest = {};
    selectLanguages.forEach((lang) => {
      htmlRequest[lang] = htmlTranslate[lang];
    });
    if (subject !== '') {
      await saveTemplateEmail(
        value,
        subject,
        '',
        buttonText,
        { ...customTemplate, title: newTitle },
        currentWorkspace,
        htmlRequest,
        newCustomEmail
      ).then((res) => {
        dispatch(setTemplate({ template: res.html, id: res.id }));
        dispatch(setSubjectEmail({ subjectEmail: subject }));
        notification.open({
          message: t('TEMPLATE_SAVED'),
          icon: <i className="fa fa-check" style={{ color: 'green' }} />,
        });
        goBack();
      });
    } else {
      notification.open({
        message: t('SUBJECT_EMAIL_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };

  const handleGenericEmail = () => {
    TrackEvent('search-send-email');
    dispatch(setTemplate({ template: value }));
    if (subject !== '') {
      dispatch(setSubjectEmail({ subjectEmail: subject }));
      setShowModal(true);
    } else {
      notification.open({
        message: t('SUBJECT_EMAIL_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };

  const handleSendEmail = async () => {
    TrackEvent('search-send-email-confirm');
    setShowModal(false);
    handleSendRequest(error, setLoading, emailsToSend, value, subject, defaultCustomEmail?.id);
  };

  return (
    <div data-testid="edit-email-template-component" className="w-100 mx-auto h-100">
      <ModalCustom
        title={t('CREATE_SEARCH_CLICK_SEND_SEARCH')}
        subtitle={t('CREATE_SEARCH_CHANGE_DATA_QUESTION')}
        show={showModal}
        setShow={setShowModal}
        onOk={handleSendEmail}
        onOkDisabled={loading}
        onCancel={() => setShowModal(false)}
        classModal="modal-wrap-add-row"
        onCancelText={t('CREATE_SEARCH_CANCEL_SEARCH')}
        onOkText={t('SEND_CUSTOM_EMAIL_BTN')}
        showCross={false}
        datatestidbuttonok="send-email-button"
      />
      <BackgroundTop
        title={`${t('CREATE_SEARCH_CAPSULE_TITLE')}${newSearch?.name && `: ${newSearch?.name}`}`}
        className="position-relative"
        titleClassname="text-xl"
      />
      <div className="px-2">
        <div className="position-relative d-flex flex-column description-container p-container-email pt-4 bg-white rounded mx-auto justify-content-around px-4">
          {viewInfo && (
            <div className="d-flex justify-content-between align-items-start">
              {step <= 3 && (
                <span className="text-sm font-montserrat text-muted">
                  {t('CREATE_SEARCH_PLACED_STEP').replace('{step}', step)}
                </span>
              )}
            </div>
          )}
          <h3 className="font-montserrat font-size-lg text-base text-title  my-3 d-flex ">
            {`${t('TITLE')}: `}
            <>
              <div>
                <input
                  onChange={(e) => (disabledTitleBtn ? null : setNewTitle(e?.target?.value))}
                  value={newTitle}
                  className={`w-100 bg-transparent   outline-none ml-2 ${
                    disabledTitleBtn
                      ? 'border-0 '
                      : 'border-1 border-top-0 border-left-0 border-right-0 border-primary'
                  } `}
                  style={{
                    flex: '1',
                  }}
                  placeholder={t('TITLE')}
                  type="text"
                  data-testid="alkemy-tests-search-input"
                />
              </div>

              <Button
                className="border-0 ml-4 p-0  "
                onClick={() => {
                  setOldTitle(newTitle);
                  setDisabledTitleBtn(!disabledTitleBtn);
                }}
              >
                {disabledTitleBtn ? <EditPencilIcon /> : <CheckIcon height="24" width="24" />}
              </Button>
              {!disabledTitleBtn && (
                <Button
                  className="border-0  p-0 ml-3"
                  onClick={() => {
                    setNewTitle(oldTitle);
                    setDisabledTitleBtn(!disabledTitleBtn);
                  }}
                >
                  <Cross />
                </Button>
              )}
            </>
          </h3>
          <h3 className="font-montserrat font-weight-500 text-sm mt-2 gray-subtitle">
            {t('TYPE_EMAIL_CUSTOM')} {t(customTemplate?.sendType)}
          </h3>
          <hr />
          <h3 className="font-montserrat font-weight-500 text-sm mt-2 gray-subtitle">
            {t('EMAIL_DASHBOARD_SUBTITLE')}
          </h3>
          <div className="d-flex flex-column flex-lg-row mt-3">
            <div className="mr-3 custom-mw-component d-flex flex-column justify-content-between">
              {emails?.length ? (
                <div className="mb-4">
                  <EmailTagsInput setEmails={setEmailsToSend} emails={emailsToSend} />
                </div>
              ) : null}

              <Select
                mode="multiple"
                style={{
                  width: '100%',
                  fontFamily: 'Montserrat',
                }}
                className="font-montserrat mb-4"
                placeholder={t('CREATE_TEST_GROUP_LANGUAGES_PLACEHOLDER')}
                onChange={onSelectLanguages}
                value={selectLanguages}
                popupClassName="font-montserrat"
                dropdownStyle={{ fontFamily: 'Montserrat' }}
              >
                {languagesList.map((x) => (
                  <Option
                    value={x.value}
                    style={{
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <CountryFlag countryIso={x.iso} size="sm" className="mr-2" />
                    {x.label}
                  </Option>
                ))}
              </Select>

              {customTemplate && (
                <div className="mb-4">
                  <Radio.Group
                    value={selectedLang ?? key}
                    onChange={(e) => setSelectedLang(e.target.value)}
                  >
                    {selectLanguages?.map((lang) => (
                      <Radio.Button value={lang}>
                        <CountryFlag
                          countryIso={languagesList?.find((item) => item?.value === lang)?.iso}
                          size="sm"
                        />
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </div>
              )}
              <input
                type="text"
                data-testid="subject-input"
                value={subject}
                placeholder={t('EMAIL_TEMPLATE_PLACEHOLDER_SUBJECT')}
                className="text-xs font-montserrat outline-none mb-2 unselected-border subject-input"
                onChange={(e) => {
                  setSubject(e.target.value);
                  setHtmlTranslate({
                    ...htmlTranslate,
                    [selectedLang]: { ...htmlTranslate[selectedLang], SUBJECT: e.target.value },
                  });
                }}
              />
              {typeof window !== 'undefined' && ReactQuill && (
                <SafeContainer
                  theme="snow"
                  content={value}
                  style="d-flex flex-column justify-content-between flex-grow-1 mb-2"
                  modulesAllow={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ align: '' }, { align: 'center' }, { align: 'right' }],
                      ['link', 'image'],
                    ],
                  }}
                  handleChange={(val) => {
                    setValue(val);
                    setHtmlTranslate({
                      ...htmlTranslate,
                      [selectedLang]: { ...htmlTranslate[selectedLang], HTML: val },
                    });
                  }}
                />
              )}
              <div className="edit-mail-info-container rounded mt-3 font-montserrat my-3 mb-lg-0 py-2 px-3">
                {customTemplate?.sendType === 'MANUAL' && (
                  <>
                    {t('THE_FIELDS_DEFAULT')}
                    <span className="font-montserrat font-weight-bold">{' {{firstName}}, '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{lastName}}, '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{companyName}}, '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{email}}, '}</span>
                  </>
                )}

                {customTemplate && customTemplate?.config?.variables && (
                  <>
                    {t('THE_FIELDS_VARIABLES')}
                    {Object.entries(customTemplate?.config?.variables)?.map(([key, value]) => (
                      <span className="font-montserrat font-weight-bold">{`{{${key}}}, `}</span>
                    ))}
                  </>
                )}
                {t('TEXT_CREATE_TEMPLATE')}
              </div>
            </div>

            <div
              className="d-flex flex-column w-100  preview-email-custom-component"
              style={{ paddingTop: '250px' }}
            >
              <p className="font-montserrat font-weight-600 purple-color text-base mb-2">
                {`${t('PREVIEW')}:`}
              </p>
              <div className="flex-grow-1 py-3 px-3 custom-overflow template-custom-shadow">
                {!customTemplate && getLocalStorageWorkspace()?.companyLogo && (
                  <div className="text-center mb-4">
                    <img
                      data-testid="company-template-image"
                      className="picture-company-email"
                      src={getLocalStorageWorkspace()?.companyLogo || ''}
                      alt={t('LOGO_COMPANY_TITLE')}
                      title={t('LOGO_COMPANY_TITLE')}
                    />
                  </div>
                )}
                <div id="divCustomPreview">{value}</div>
                {!customTemplate && (
                  <div className="text-center">
                    <button
                      className="button-screening-continue px-4 border-0"
                      type="button"
                      data-testid="example-template-button"
                      onClick={() => {}}
                    >
                      <span className="button-screening-continue-text text-sm font-roboto font-weight-bold">
                        {buttonText}
                        <img src={arrowRight} alt={t('ICON_ARROW')} className="pl-2 mb-1" />
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mt-3">
            <button
              data-testid="go-back-button"
              className="button-screening-continue px-4 py-1 rounded-0 my-3 mr-auto"
              type="button"
              onClick={() => {
                if (goBack) {
                  goBack();
                } else {
                  history.goBack();
                }
              }}
            >
              <span className="button-screening-continue-text font-montserrat text-xs font-weight-bold">
                {t('CREATE_SEARCH_STAGE_BACK')}
              </span>
            </button>
            {customTemplate && (
              <button
                disabled={loading}
                data-testid="send-evaluation-button"
                className="button-screening-continue ml-auto px-4 py-1 rounded-0 my-3 bg-success"
                type="button"
                onClick={handleSaveCustomEmail}
              >
                <span className="button-screening-continue-text font-montserrat text-xs font-weight-bold">
                  {newCustomEmail ? t('CREATE') : t('SAVE')}
                </span>
              </button>
            )}
            <button
              disabled={loading}
              data-testid="send-evaluation-button"
              className="button-screening-continue ml-auto px-4 py-1 rounded-0 my-3"
              type="button"
              onClick={emailDashboard ? handleGenericEmail : handleCustomEmail}
            >
              <span className="button-screening-continue-text font-montserrat text-xs font-weight-bold">
                {t('SEND')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomEmailTemplate;
