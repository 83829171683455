export function generateArrayOfDays(daysInMonth, callback) {
  const days = Array.from({ length: daysInMonth }, (_, num) => num + 1);
  const prevDays = Array.from({ length: 31 }, (_, num) => num + 1);

  return !days.length ? callback(prevDays) : callback(days);
}

export function generateArrayOfYears() {
  const min = new Date().getFullYear();
  const max = min + 10;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ id: i, name: i });
  }
  return years.sort((a, b) => a.id - b.id);
}

export function userUtcOffset() {
  return -new Date().getTimezoneOffset() / 60;
}
