import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Progress, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../i18n/i18n';
import {
  getPsychotechnicByScreeningInstanceId,
  getScreeningByInstanceId,
  getSubmissionAnswers,
  getTestGroups,
  getTestGroupsDetailsBatch,
} from '../../API/services/screeningService';
import { setInvitedProfileTestGroups } from '../../redux/actions/profilesActions';
import { getIntegerPart, oneDecimal } from '../../utils/numbersUtils';
import { setSubmissionSelected } from '../../redux/actions/screeningActions';
import InvitedProfileComments from '../InvitedProfileComments/InvitedProfileComments';
import Collapsed from '../Collapsed/Collapsed';
import './InvitedProfileStages.scss';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import {
  getCandidatePdfExport,
  getCandidateQualitativePdfExport,
  getProfileSkills,
  getPsychotechnicExportReport,
} from '../../API/services/alkymersService';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import InvitedProfileCommunications from '../InvitedProfileCommunications/InvitedProfileCommunications';
import CloudDownload from '../../assets/dashboard/CloudDownload';
import { isNullOrUndefined } from '../../utils/typesUtils';
import {
  ProcessStatusStage,
  ProfileDataTypes,
  ReviewTypeEnum,
  TestGroupMetricsRol,
  TestGroupTypes,
} from '../../constants/enums';
import QualitativeCollapsed from './QualitativeCollapsed/QualitativeCollapsed';
import Spinner from '../spinner/Spinner';
import ApplicationMethod from './ApplicationMethod/ApplicationMethod';
import ProfileInvitationsAndChallenges from './ProfileInvitationsAndChallenges/ProfileInvitationsAndChallenges';
import DropdownSelectDot from '../DropdownSelectDot/DropdownSelectDot';
import { INVITED_PROFILES_PROCTORING_DETAIL } from '../../constants/routes';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import useTestGroupMetrics from '../../hooks/useTestGroupMetrics';
import { getPercentile } from '../../utils/stats/statistics';
import { downloadFile, downloadBase64Pdf } from '../../utils/downloadFile';

const InvitedProfileStages = ({
  setShow,
  profile,
  isInvitedProfile,
  score,
  setGroupAcceleration,
  updateStageSingleCandidate,
  uniqueProfileData,
}) => {
  const [selectedStage] = useState(0);
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(undefined);
  const [loadingTestGroups, setLoadingTestGroups] = useState(isInvitedProfile);
  const { partner } = useSelector((state) => state.auth.user);
  const [screeningInstanceId, setScreeningInstanceId] = useState('');
  const [prefilterInstanceId, setPrefilterInstanceId] = useState('');
  const [screeningInstance, setScreeningInstance] = useState(null);
  const [psychotechnic, setPsychotechnic] = useState(null);
  const [groupsProportion, setGroupsProportion] = useState({});
  const { invitedProfileTestGroups: testGroups } = useSelector((state) => state.profiles);
  const [resultsToShow, setResultsToShow] = useState(1);
  const {
    roleMetricSelected,
    companyName,
    companyId,
    companyMetricSelected,
    setCompanyMetricSelected,
  } = useTestGroupMetrics({
    testGroup: {},
  });
  const dispatch = useDispatch();

  const isEvaluation = uniqueProfileData?.type === ProfileDataTypes.EVALUATION;
  const isChallenge = uniqueProfileData?.type === ProfileDataTypes.CHALLENGE;
  const screeningName = isEvaluation
    ? uniqueProfileData?.data?.[0]?.invitation?.screeningName
    : undefined;
  const invitationData = uniqueProfileData?.data?.[selectedStage]?.invitation ?? null;
  const visibleTechnologies = profile?.technologies?.filter((tech) => tech?.technology?.visible);
  const [qualitativeResultType, setQualitativeResultType] = useState('');
  const invitedDate =
    invitationData && uniqueProfileData?.data?.[selectedStage]?.invitation?.createdAt
      ? format(
          new Date(uniqueProfileData?.data?.[selectedStage]?.invitation?.createdAt),
          'dd/MM/yyyy'
        )
      : '-';
  const submittedDate =
    invitationData && uniqueProfileData?.data?.[selectedStage]?.invitation?.endDate
      ? format(
          new Date(uniqueProfileData?.data?.[selectedStage]?.invitation?.endDate),
          'dd/MM/yyyy'
        )
      : '-';

  useEffect(async () => {
    setLoadingTestGroups(true);
    const testgroupsIds = uniqueProfileData?.data
      ?.map((stage) => stage?.invitation?.screeningInstanceId)
      .filter((id) => Boolean(id));
    let testGroups = [];
    if (testgroupsIds.length) {
      testGroups = await getTestGroups(testgroupsIds);
    }
    if (uniqueProfileData?.data?.[0]?.invitation?.prefilterInstanceId) {
      const prefilterTest = await getTestGroups(
        uniqueProfileData?.data?.[0]?.invitation?.prefilterInstanceId
      );
      testGroups?.unshift(prefilterTest[0]);
      setPrefilterInstanceId(uniqueProfileData?.data?.[0]?.invitation?.prefilterInstanceId);
    }
    if (testGroups.length) {
      if (
        testGroups?.some((x) => x?.testGroups?.some((tg) => tg.type === TestGroupTypes.QUALITATIVE))
      ) {
        setCollapsedSelected([0]);
        const qualitativeTestGroupIds = testGroups
          ?.flatMap((x) => x.testGroups)
          .filter((tg) => tg.type === TestGroupTypes.QUALITATIVE)
          .map((tg) => tg.id);
        const qualitativeTestGroupsData = await getTestGroupsDetailsBatch(qualitativeTestGroupIds);
        qualitativeTestGroupsData.forEach((test) => {
          const instanceTestGroups = testGroups.find(
            (tg) =>
              tg.id === uniqueProfileData?.data?.[selectedStage]?.invitation?.screeningInstanceId
          ).testGroups;
          const testGroup = instanceTestGroups.find((tg) => tg.id === test.id);
          testGroup.completeData = test;
        });
      }

      const formated = testGroups?.reduce(
        (object, instance) => ({ ...object, [instance.id]: instance.testGroups }),
        {}
      );
      const proportions = {};
      Object.entries(formated).forEach(([id, testGroup]) => {
        const totalWeight = testGroup.reduce((sum, test) => sum + test.weight, 0);

        proportions[id] = 100 / totalWeight;
      });
      setLoadingTestGroups(false);
      setGroupsProportion(proportions);
      dispatch(setInvitedProfileTestGroups(formated));
      const screeningInstanceIdCopy =
        uniqueProfileData?.data?.[selectedStage]?.invitation?.screeningInstanceId;
      setScreeningInstanceId(screeningInstanceIdCopy);
    } else {
      setLoadingTestGroups(false);
    }

    return () => setInvitedProfileTestGroups({});
  }, [uniqueProfileData.data]);

  useEffect(() => {
    const screeningInstanceIdCopy =
      uniqueProfileData?.data?.[selectedStage]?.invitation?.screeningInstanceId;
    setScreeningInstanceId(screeningInstanceIdCopy);
    const getScreeningInstance = async () => {
      try {
        const screeningInstanceCopy = await getScreeningByInstanceId(screeningInstanceIdCopy);
        setScreeningInstance(screeningInstanceCopy);
        const psychotechnic = await getPsychotechnicByScreeningInstanceId(screeningInstanceIdCopy);
        setPsychotechnic(psychotechnic);
      } catch (e) {
        console.log(e);
      }
    };
    if (screeningInstanceIdCopy) {
      getScreeningInstance();
    }
  }, [selectedStage]);

  const getDataSubmission = async (screeningInstanceId, testGroup, index) => {
    setLoadingDetail(index);
    await getSubmissionAnswers(screeningInstanceId, testGroup?.id).then((res) => {
      dispatch(setSubmissionSelected({ testGroup, submissions: res }));
      setLoadingDetail(undefined);
      setShow(true);
    });
  };

  const optionsCollapsed = [
    {
      title: t('PROFILE_QUALITITAVE_RESULTS'),
      content: (
        <>
          {testGroups[screeningInstanceId]?.some(
            (testgroup) => testgroup.type === TestGroupTypes.QUALITATIVE
          ) ? (
            <QualitativeCollapsed
              testgroups={testGroups[screeningInstanceId]?.filter(
                (testgroup) => testgroup.type === TestGroupTypes.QUALITATIVE
              )}
              recruitSessionId={uniqueProfileData?.data?.[0]?.recruitSessionStage?.recruitSessionId}
              profile={profile}
              qualitativeResultType={qualitativeResultType}
              setQualitativeResultType={setQualitativeResultType}
            />
          ) : (
            <EmptyComponent
              title={t('INVITED_PROFILE_EMPTY_TITLE_SECTION')}
              className="my-3 align-items-center py-3"
            />
          )}
        </>
      ),
      isVisible: testGroups[screeningInstanceId]?.some(
        (testgroup) => testgroup.type === TestGroupTypes.QUALITATIVE
      ),
      collapsedEnabled: true,
    },
    {
      title: t('PROFILE_COMMENTS'),
      content: <InvitedProfileComments isInvitedProfile={isInvitedProfile} />,
      isVisible: true,
      collapsedEnabled: false,
      containerClassname: 'py-0 mt-3',
    },
    {
      title: t('SEARCH_COMMUNICATION_HISTORY_TITLE'),
      content: <InvitedProfileCommunications isInvitedProfile={isInvitedProfile} />,
      isVisible: isInvitedProfile,
      collapsedEnabled: true,
      containerClassname: 'py-0 mt-3  mx-auto pt-3 b-radius-8',
      titleClassnames: 'px-4 justify-content-between  font-weight-600 text-base text-black pb-2',
    },
    {
      title: t('PROFILE_METHOD_APPLIED'),
      content: (
        <>
          <ApplicationMethod recruitSessionStage={invitationData} />
        </>
      ),
      isVisible: true,
      collapsedEnabled: true,
      containerClassname: 'py-0 mt-3  mx-auto pt-3 b-radius-8',
      titleClassnames: 'px-4 justify-content-between  font-weight-600 text-base text-black pb-2',
    },
  ];

  const setProfileNotInvited = async (progressTech, index) => {
    setGroupAcceleration({});
    dispatch(setSubmissionSelected({}));
    setLoadingDetail(index);
    try {
      if (!progressTech?.screeningInstanceId) {
        await getProfileSkills(progressTech?.id).then((res) => {
          setGroupAcceleration({ ...progressTech, skills: res.skills });
          setLoadingDetail(undefined);
          setShow(true);
        });
      } else {
        const screeningInstanceCopy = await getScreeningByInstanceId(
          progressTech?.screeningInstanceId
        );
        if (screeningInstanceCopy) {
          await getDataSubmission(
            progressTech?.screeningInstanceId,
            screeningInstanceCopy.testGroups[0],
            index
          );
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDetail(undefined);
    }
  };

  const [reportLoading, setReportLoading] = useState(false);

  const generatePsychotechnicReport = async () => {
    setReportLoading(true);
    await getPsychotechnicExportReport(screeningInstanceId)
      .then((res) => {
        const blob = window.URL.createObjectURL(res);
        const fileLink = document.createElement('a');
        fileLink.href = blob;
        fileLink.setAttribute('download', `psychotechnic.pdf`);
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);
        fileLink.click();
        setReportLoading(false);
      })
      .catch(() => {
        setReportLoading(false);
      });
  };

  const showDownloadButton = () => {
    if (screeningInstance?.screening?.reviewType === ReviewTypeEnum.PSYCHOTECHNICS) {
      return psychotechnic?.status === 'FINISHED';
    }
    return true;
  };

  const generateUserReport = async () => {
    setReportLoading(true);
    if (screeningInstance?.screening?.reviewType === ReviewTypeEnum.PSYCHOTECHNICS) {
      if (psychotechnic?.status === 'FINISHED') {
        generatePsychotechnicReport();
      } else {
        notification.open({
          message: t('PSYCHOTECHNIC_WAITING'),
          type: 'info',
        });
        setReportLoading(false);
      }
      return;
    }

    const newVersionOfQualitative =
      screeningInstanceId &&
      testGroups[screeningInstanceId][0]?.completeData?.qualitativeConfigResults &&
      !testGroups[screeningInstanceId][0]?.completeData?.qualitativeConfigResults?.type;

    try {
      const recruitSessionId = uniqueProfileData?.data?.[0]?.recruitSessionStage?.recruitSessionId;
      const profileId = encodeURI(profile?.id);
      const res = await getCandidatePdfExport(recruitSessionId, profileId);
      const blob = window.URL.createObjectURL(res);
      const fileName = `${profile?.firstName?.toLowerCase()}-${profile?.lastName?.toLowerCase()}.pdf`;
      downloadFile(blob, fileName);
    } catch (error) {
      console.error('Error generating candidate report:', error);
    }
    try {
      if (newVersionOfQualitative) {
        const recruitSessionId =
          uniqueProfileData?.data?.[0]?.recruitSessionStage?.recruitSessionId;
        const profileId = encodeURI(profile?.id);

        const res = await getCandidateQualitativePdfExport(recruitSessionId, profileId);
        res?.forEach((base64String, index) => {
          const filename = `${profile.firstName.toLowerCase()}-${profile.lastName.toLowerCase()}-qualitative-${
            index + 1
          }.pdf`;
          downloadBase64Pdf(base64String, filename); // Use the utility function
        });
      }
    } catch (error) {
      console.error('Error generating qualitative report:', error);
    } finally {
      setReportLoading(false);
    }
  };

  const prefilterTestGroupPreview = (titleLabel) => (
    <>
      <div data-testid="prefilter-tests-container">
        {testGroups[prefilterInstanceId]?.map((testGroup, index) => (
          <>
            <div className="d-flex flex-column mt-3">
              <div className="d-flex flex-row align-items-center bg-white">
                <span className=" text-xs font-weight-bold text-capitalize container-name-mw">
                  {t(titleLabel)}
                </span>
                <button
                  className="border-0 bg-transparent cursor-pointer text-decoration-none d-flex button-detail-mw"
                  onClick={() => getDataSubmission(prefilterInstanceId, testGroup, index - 1)}
                  type="button"
                >
                  {!(loadingDetail === index - 1) ? (
                    <span className=" font-weight-600 span-see-detail-modal text-xs ml-2">
                      {t('DEV_PROFILE_TESTGROUP_VIEW_DETAIL')}
                    </span>
                  ) : (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 18,
                          }}
                          spin
                        />
                      }
                      className="ml-2"
                    />
                  )}
                </button>
              </div>
              <Progress
                strokeWidth={8}
                className="invited-profile-stages-progress"
                strokeColor={{
                  '30%': '#4987BE',
                  '100%': '#9BF558',
                }}
                percent={testGroup.score * 100 ?? 0}
                format={() => (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {isEvaluation ? `pts ${Math.round(testGroup.score * 100)}/100` : ''}
                      </Tooltip>
                    }
                  >
                    <span className=" font-weight-bold invited-profile-skill-title pl-4 text-xs points-text">
                      {`${Math.round(testGroup.score * 100)} % `}
                    </span>
                  </OverlayTrigger>
                )}
              />
            </div>
          </>
        ))}
      </div>
    </>
  );
  const processStatusOptions = Object.values(ProcessStatusStage).map((status) => ({
    name: t(`SCREENING_INVITATIONS_PROCESS_STATUS_${status}`),
    value: status,
  }));

  const hasQuantitativeTestgroups =
    isEvaluation &&
    testGroups[screeningInstanceId]?.some(
      (testgroup) => testgroup.type === TestGroupTypes.QUANTITATIVE
    );

  const getTestGroupMetricDetail = (tg) => useTestGroupMetrics({ testGroup: tg });

  const getPonderatedPercentil = () => {
    const testgroupOfInstance = testGroups[screeningInstanceId];
    const percentajeWithMetrics = testgroupOfInstance
      ?.filter(
        ({ type, metrics }) => type === TestGroupTypes.QUANTITATIVE && metrics?.dataGrouped?.length
      )
      ?.reduce((sum, t) => sum + t.weight, 0);
    const totalWeightedSum = testgroupOfInstance
      ?.filter(
        ({ type, metrics }) => type === TestGroupTypes.QUANTITATIVE && metrics?.dataGrouped?.length
      )
      ?.reduce((sum, t) => {
        const metricDataSelected = t?.metrics?.dataGrouped?.find(
          (d) => d.companyId === companyMetricSelected
        )?.roleMetrics[roleMetricSelected];
        if (metricDataSelected) {
          const percentile = getPercentile(t?.score, metricDataSelected?.percentiles);
          return sum + (percentile * t.weight) / percentajeWithMetrics;
        }
        return sum;
      }, 0);
    return totalWeightedSum;
  };

  const containerTopRight = () => {
    return (
      <>
        {resultsToShow === 1 ? (
          invitationData && isNullOrUndefined(invitationData.score) ? (
            <button
              type="button"
              className="cursor-not-allowed report-btn-disabled-text px-3 py-1 text-xs cursor-pointer rounded-0 d-flex align-items-center font-weight-700 bg-white report-btn-disabled-button"
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{t('REPORT_DISABLED_EVALUATION_NOT_COMPLETED_YET')}</Tooltip>}
              >
                <div>
                  <span className="report-btn-disabled-text mr-2 ">
                    {t('CANDIDATE_DOWNLOAD_REPORT')}
                  </span>
                  <CloudDownload fill="#959595" />
                </div>
              </OverlayTrigger>
            </button>
          ) : (
            showDownloadButton() && (
              <button
                onClick={generateUserReport}
                type="button"
                className="text-blue-principal px-3 py-1 text-xs border-maya-blue cursor-pointer rounded-0 d-flex align-items-center font-weight-700  bg-white border-button"
              >
                {reportLoading ? (
                  <HorizontalLoader className="loader-report" />
                ) : (
                  <>
                    <span className="text-blue-principal mr-2 ">
                      {t('CANDIDATE_DOWNLOAD_REPORT')}
                    </span>
                    <CloudDownload fill="#4987BE" />
                  </>
                )}
              </button>
            )
          )
        ) : (
          <>
            <DropdownSelect
              key="results-relative-company-selector"
              options={[
                {
                  id: 0,
                  displayName: TestGroupMetricsRol.GENERAL_USER_ROLES,
                  visible: testGroups?.[screeningInstanceId]?.some(
                    (testgroup) =>
                      testgroup?.metrics?.dataGrouped?.[0]?.roleMetrics[roleMetricSelected]
                  ),
                },
                {
                  id: companyId,
                  displayName: companyName,
                  visible: testGroups?.[screeningInstanceId]?.every(
                    (testgroup) =>
                      testgroup?.metrics?.dataGrouped?.[companyId]?.roleMetrics[roleMetricSelected]
                  ),
                },
              ]
                .filter((item) => item.visible)
                .map((item) => ({
                  id: item.id,
                  name: (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {t('METRIC_PROFILE_COMPARE_DETAIL_OVERLAY')?.replace(
                            '{{company}}',
                            item.displayName
                          )}
                        </Tooltip>
                      }
                    >
                      <span>{t(item.displayName)}</span>
                    </OverlayTrigger>
                  ),
                }))}
              className="w-100 position-relative"
              selected={companyMetricSelected}
              selectedPlaceholder
              placeholderClassname="mr-2 text-xs font-weight-500"
              axHeight={false}
              bigArrowLightBlue
              arrow={false}
              placeholder={t('METRIC_PROFILE_COMPARE_DETAIL_OVERLAY')}
              setState={(e) => {
                setCompanyMetricSelected(e?.id);
              }}
            />
          </>
        )}
      </>
    );
  };

  const fullTestScore = () => {
    const ponderatedResult = getPonderatedPercentil();
    return (
      <div className="d-flex flex-column">
        <span className="font-weight-bold  mb-2 invited-profile-stage-percent">
          {resultsToShow === 1 ? (
            isEvaluation ? (
              `${
                Number.isNaN(
                  oneDecimal(uniqueProfileData?.data?.[selectedStage]?.invitation?.score)
                )
                  ? '-'
                  : `${oneDecimal(uniqueProfileData?.data?.[selectedStage]?.invitation?.score)}%`
              }`
            ) : Number.isNaN(
                uniqueProfileData?.data?.find((technology) => technology?.isPrimary)?.bestScore
              ) ? (
              <HorizontalLoader className="loader-classname" />
            ) : (
              `${oneDecimal(
                Math.round(
                  uniqueProfileData?.data?.find((technology) => technology?.isPrimary)?.bestScore
                )
              )}%`
            )
          ) : Number.isNaN(oneDecimal(ponderatedResult)) ? (
            '-'
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{t('PONDERATED_PERCENTIL_CANDIDATE_ON_TESTGROUP')}</Tooltip>}
            >
              <span>{getIntegerPart(ponderatedResult)}</span>
            </OverlayTrigger>
          )}
        </span>
      </div>
    );
  };

  const getTestGroupPercentil = (tgId) => {
    const tg = testGroups[screeningInstanceId].find((t) => t.id === tgId);
    const metricDataSelected = tg?.metrics?.dataGrouped?.find(
      (d) => d.companyId === companyMetricSelected
    )?.roleMetrics[roleMetricSelected];
    if (metricDataSelected) {
      return getPercentile(tg.score, metricDataSelected?.percentiles);
    }
    return 0;
  };

  const getTestGroupTotalPercentil = (testGroup) => {
    const { metricDataSelected } = getTestGroupMetricDetail(testGroup);
    const display = {
      percentil: metricDataSelected ? (
        getPercentile(testGroup.score, metricDataSelected?.percentiles)
      ) : (
        <>
          <FontAwesomeIcon icon={faInfoCircle} className="no-metrics-icon" />
        </>
      ),
      overlay: metricDataSelected
        ? 'PERCENTIL_FOR_CANDIDATE_ON_TESTGROUP'
        : 'NO_DATA_ENOUGH_FOR_CANDIDATE_ON_TESTGROUP',
    };
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{t(display.overlay)}</Tooltip>}
        key={`tesgroup-percentil-detail-${companyMetricSelected}`}
      >
        <span
          className="font-weight-bold invited-profile-skill-title pl-4 text-xs points-text no-metrics-icon"
          key={`tg-percentil-in-${display.percentil}`}
        >
          {display.percentil}
        </span>
      </OverlayTrigger>
    );
  };

  const getTestGroupPercentage = (testGroup) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {isEvaluation
              ? `pts ${Math.round(
                  (testGroup.score ?? 0) /
                    (100 / (testGroup.weight * groupsProportion[screeningInstanceId] ?? 0))
                )}/${Math.round(testGroup.weight * groupsProportion[screeningInstanceId] ?? 0)}`
              : ''}
          </Tooltip>
        }
      >
        <span className=" font-weight-bold invited-profile-skill-title pl-4 text-xs points-text">
          {`${Math.round(testGroup.score * 10) / 10} % `}
        </span>
      </OverlayTrigger>
    );
  };

  useEffect(() => {
    setResultsToShow(1);
    setCompanyMetricSelected(0);
  }, [screeningInstanceId]);

  return (
    <div
      data-testid="invited-profile-stages-container"
      className="px-4 pb-4 pt-0 invited-profile-stages-card flex-grow-1 h-100"
    >
      <ProfileInvitationsAndChallenges />
      {isEvaluation && (
        <>
          {isEvaluation && (
            <div className="d-flex justify-content-between mt-3">
              <div className="d-flex flex-column">
                <span className="text-md font-weight-600">{screeningName}</span>
                <span className="text-sm text-muted font-weight-400">{`${t(
                  'PROFILE_INVITED_DATE'
                )} ${invitedDate} | ${t('PROFILE_SUBMITTED_DATE')} ${submittedDate}`}</span>
              </div>
              <div className="d-flex flex-column justify-content-end">
                <span>{t('PROFILE_EVALUATION_STATUS')}</span>
                <DropdownSelectDot
                  className="py-0 font-montserrat text-12-px border-blue-principal b-radius-8"
                  placeholder={t('DASHBOARD_INVITATIONS_PROCESS_STATUS')}
                  listSelect
                  arrowLightBlue
                  options={processStatusOptions}
                  dot
                  selectedPlaceholder
                  selected={uniqueProfileData?.data?.[0]?.invitation?.processStatus}
                  setState={(e) =>
                    updateStageSingleCandidate(e.id, uniqueProfileData?.data?.[0]?.invitation?.id)
                  }
                  padding={false}
                  fullWidth={false}
                  alignCenter={'align-self-end align-self-sm-center'}
                />
              </div>
            </div>
          )}
        </>
      )}

      <div
        className={`card-profile description-container position-relative h-100 bg-white b-radius-8 mt-3`}
      >
        <div className="d-flex flex-column invited-profile-stages-container p-4">
          <div className="d-flex justify-content-between">
            <DropdownSelect
              key="results-profile-selector"
              options={[
                {
                  id: 1,
                  displayName: 'PROFILE_QUANTITAVE_RESULTS',
                  visible: true,
                  overlay: 'PROFILE_QUANTITAVE_RESULTS_OVERLAY',
                },
                {
                  id: 2,
                  displayName: 'PROFILE_RELATIVE_RESULTS',
                  visible:
                    testGroups[screeningInstanceId]?.some(
                      (testgroup) => testgroup?.metrics?.dataGrouped?.length > 0
                    ) && uniqueProfileData?.data?.[selectedStage]?.invitation?.score,
                  overlay: 'PROFILE_RELATIVE_RESULTS_OVERLAY',
                },
              ]
                .filter((item) => item.visible)
                .map((item) => ({
                  id: item.id,
                  name: (
                    <OverlayTrigger placement="top" overlay={<Tooltip>{t(item.overlay)}</Tooltip>}>
                      <span>{t(item.displayName)}</span>
                    </OverlayTrigger>
                  ),
                }))}
              className="w-100 position-relative"
              selected={resultsToShow}
              selectedPlaceholder
              placeholderClassname="mr-2 text-xs font-weight-500"
              axHeight={false}
              bigArrowLightBlue
              arrow={false}
              placeholder={t('SELECT_RESULT_TYPE')}
              disabled={isNullOrUndefined(
                uniqueProfileData?.data?.[selectedStage]?.invitation?.score
              )}
              setState={(e) => {
                setResultsToShow(e?.id);
              }}
            />
            {isEvaluation && containerTopRight()}
          </div>
          {isChallenge || invitationData?.score || invitationData?.score === 0 ? (
            <div className="d-flex mt-3 px-3 flex-column flex-sm-row">
              {(hasQuantitativeTestgroups || isChallenge) && (
                <div className="pr-sm-5 text-center d-flex flex-column">
                  <Progress
                    type="circle"
                    strokeWidth={4}
                    strokeColor={{
                      '30%': '#4987BE',
                      '100%': '#9BF558',
                    }}
                    className="mb-3 mb-lg-0"
                    width={140}
                    percent={
                      resultsToShow === 1
                        ? isEvaluation
                          ? uniqueProfileData?.data?.[selectedStage]?.invitation?.score ?? 0
                          : Math.round(
                              uniqueProfileData?.data?.find((technology) => technology?.isPrimary)
                                ?.bestScore
                            )
                        : oneDecimal(getPonderatedPercentil())
                    }
                    format={() => fullTestScore()}
                  />
                  {process.env.RAZZLE_RUNTIME_IS_PROCTORING_ENABLED === 'true' &&
                    isEvaluation &&
                    uniqueProfileData?.data?.[0]?.recruitSessionStage?.proctoringEnabled &&
                    screeningInstanceId !== undefined &&
                    uniqueProfileData?.data?.[selectedStage]?.invitation?.endDate && (
                      <div className="d-flex flex-column mt-3">
                        {Boolean(
                          uniqueProfileData?.data?.[selectedStage]?.invitation?.totalEvents
                        ) && (
                          <span className="text-nowrap  text-black text-xs font-weight-600 mb-1">
                            {t('ASSESSMENT_INVITATION_TOTAL_EVENTS').replace(
                              '{totalEvents}',
                              uniqueProfileData?.data?.[selectedStage]?.invitation?.totalEvents
                            )}
                          </span>
                        )}
                        <Link
                          to={INVITED_PROFILES_PROCTORING_DETAIL.replace(
                            ':recruitSessionId',
                            uniqueProfileData?.data?.[0]?.recruitSessionStage?.recruitSessionId
                          ).replace(':profileId', profile?.id)}
                          className=" text-purple text-xs text-nowrap font-weight-600"
                        >
                          {t('VIEW_PROCTORING_ANALYSIS')}
                        </Link>
                      </div>
                    )}
                  {isChallenge && (
                    <span className=" font-weight-600 text-xs mt-2">
                      {
                        uniqueProfileData?.data?.find((technology) => technology?.isPrimary)
                          ?.technology?.displayName
                      }
                    </span>
                  )}
                </div>
              )}
              <div className="section-container">
                <div
                  className="d-flex flex-column"
                  key={`testgroup-results-${resultsToShow}-${companyMetricSelected}`}
                >
                  {isEvaluation ? (
                    <div className="d-flex flex-column justify-content-between mb-2">
                      <div className="d-flex flex-column">
                        <span
                          data-testid="invited-profile-stages-screening-name"
                          className=" text-xs font-weight-bold text-capitalize"
                        >
                          {screeningName}
                        </span>
                      </div>
                      {loadingTestGroups ? (
                        <Spinner show />
                      ) : hasQuantitativeTestgroups ? (
                        <div data-testid="quantitative-tests-container" key={nanoid()}>
                          {prefilterTestGroupPreview('TESTGROUP_PRE_FILTER_TITLE_NAME')}
                          {testGroups[screeningInstanceId]
                            ?.filter((testgroup) => testgroup.type === TestGroupTypes.QUANTITATIVE)
                            ?.map((testGroup, index) => {
                              const progressPercentage = getTestGroupPercentil(testGroup?.id);
                              const progressResult = () =>
                                resultsToShow === 1
                                  ? getTestGroupPercentage(testGroup)
                                  : getTestGroupTotalPercentil(testGroup);
                              return (
                                <>
                                  <div className="d-flex flex-column mt-3" key={nanoid()}>
                                    <div className="d-flex flex-row align-items-center bg-white">
                                      <span className=" text-xs font-weight-bold text-capitalize container-name-mw">
                                        {testGroup.name}
                                      </span>
                                      {(screeningInstance?.screening?.reviewType ===
                                      ReviewTypeEnum.PSYCHOTECHNICS
                                        ? !testGroup?.categories?.find(
                                            (item) => item?.name === 'Proyectivas'
                                          )
                                        : true) && (
                                        <button
                                          className="border-0 bg-transparent cursor-pointer text-decoration-none d-flex button-detail-mw"
                                          onClick={() =>
                                            getDataSubmission(screeningInstanceId, testGroup, index)
                                          }
                                          type="button"
                                        >
                                          {!(loadingDetail === index) ? (
                                            <span className=" font-weight-600 span-see-detail-modal text-xs ml-2">
                                              {t('DEV_PROFILE_TESTGROUP_VIEW_DETAIL')}
                                            </span>
                                          ) : (
                                            <Spin
                                              indicator={
                                                <LoadingOutlined
                                                  style={{
                                                    fontSize: 18,
                                                  }}
                                                  spin
                                                />
                                              }
                                              className="ml-2"
                                            />
                                          )}
                                        </button>
                                      )}
                                    </div>

                                    <Progress
                                      key={`testgroup-results-progress-${resultsToShow}-${companyMetricSelected}`}
                                      strokeWidth={8}
                                      className="invited-profile-stages-progress"
                                      strokeColor={{
                                        '30%': '#4987BE',
                                        '100%': '#9BF558',
                                      }}
                                      percent={
                                        resultsToShow === 1
                                          ? testGroup.score ?? 0
                                          : progressPercentage
                                      }
                                      format={() => progressResult()}
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      ) : (
                        <span className=" text-xs font-weight-400 mt-2">
                          {t('QUANTITATIVE_TEST_EMPTY_STATE')}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-between mb-2">
                      <div className="">
                        {uniqueProfileData?.data?.map((progressTech, index) => (
                          <>
                            <div className="d-flex flex-column mt-3" key={nanoid()}>
                              <div className="invited-profile-stages-progress d-flex flex-row align-items-center justify-content-between">
                                <span className=" text-xs font-weight-bold">
                                  {progressTech?.technology?.displayName}
                                </span>
                                <div className="d-flex align-items-center justify-content-center">
                                  <button
                                    className="border-0 bg-transparent cursor-pointer text-decoration-none d-flex"
                                    onClick={() => {
                                      setProfileNotInvited(progressTech, index);
                                      TrackEvent('Profile-details-challenge-details', {
                                        profile,
                                        partner,
                                      });
                                    }}
                                    type="button"
                                  >
                                    {loadingDetail !== index ? (
                                      <span className=" font-weight-600 span-see-detail-modal text-xs ml-2">
                                        {t('DEV_PROFILE_TESTGROUP_VIEW_DETAIL')}
                                      </span>
                                    ) : (
                                      <Spin
                                        indicator={
                                          <LoadingOutlined
                                            style={{
                                              fontSize: 18,
                                            }}
                                            spin
                                          />
                                        }
                                        className="ml-2"
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                              <Progress
                                strokeWidth={8}
                                className="invited-profile-stages-progress"
                                format={() => {}}
                                strokeColor={{
                                  '30%': '#4987BE',
                                  '100%': '#9BF558',
                                }}
                                percent={Math.round(progressTech?.bestScore)}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : invitationData?.prefilterScore ||
            (invitationData?.prefilterScore >= 0 && invitationData?.prefilterScore !== null) ? (
            <>
              <div className="d-flex mt-3 p-3 flex-column flex-sm-row">
                <div className="pr-sm-3 text-center d-flex flex-column mb-3">
                  <div className="rounded-circle circle-no-data d-flex flex-column justify-content-center">
                    <p className=" text-base font-weight-400 text-center mb-0">
                      {t('CANDIDATE_DETAIL_NO_TEST_TAKEN')}
                    </p>
                  </div>
                </div>
                <div className="section-container">
                  <div className="d-flex flex-column">
                    <span className=" text-xs font-weight-bold text-capitalize">
                      {t('TESTGROUP_PRE_FILTER_TITLE_NAME')}
                    </span>
                    {invitationData.prefilterScore < 1 ? (
                      <p className=" text-xs mt-3">{t('PREFILTER_TEST_NOT_PASSED_PREVIEW')}</p>
                    ) : invitationData?.limitDate &&
                      new Date() < new Date(invitationData.limitDate) ? (
                      <p className=" text-xs mt-3">
                        {t('PREFILTER_TEST_PASSED_PREVIEW_NOT_STARTED')}
                      </p>
                    ) : (
                      <p className=" text-xs mt-3">
                        {t('DASHBOARD_SEARCH_HEADERS_SCREENINGS')}: {t(`SEARCH_EXPIRED`)}
                      </p>
                    )}
                  </div>
                  <>{prefilterTestGroupPreview('QUESTIONS')}</>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex mt-3 p-3 flex-column flex-sm-row">
              <div className="pr-sm-3 text-center d-flex flex-column mb-3">
                <div className="rounded-circle circle-no-data d-flex flex-column justify-content-center">
                  <p className=" text-base font-weight-400 text-center mb-0">
                    {t('CANDIDATE_DETAIL_NO_DATA')}
                  </p>
                </div>
              </div>
              <div className="section-container">
                <div className="d-flex flex-column">
                  <span className=" text-xs font-weight-bold text-capitalize">{screeningName}</span>
                  <p className=" text-xs mt-3">
                    {t('DASHBOARD_SEARCH_HEADERS_SCREENINGS')}
                    {': '}
                    {invitationData?.endDate || !isNullOrUndefined(invitationData?.score)
                      ? t('SCREENINGS_INVITATIONS_PROCESSING')
                      : t(`SCREENINGS_INVITATIONS_${invitationData?.status}`)}
                  </p>
                  <p className=" text-xs">{t('CANDIDATE_DETAIL_EMPTY_STATE_TEXT')}</p>
                </div>
              </div>
            </div>
          )}
          {!isInvitedProfile && (
            <>
              {Boolean(visibleTechnologies?.length) && (
                <div className="mt-5 d-flex">
                  <span className=" font-weight-600 text-xs technologies-text">
                    {t('PROFILE_TECHNOLOGIES_USER_KNOWS')}
                    <span className=" font-weight-500 text-xs mx-1 mb-1 technology-text-value">
                      {visibleTechnologies
                        ?.filter((tech) => tech.technologyType === 'KNOW')
                        ?.map(
                          (tech, i) =>
                            ` ${tech?.technology?.displayName}${
                              i ===
                              visibleTechnologies.filter(
                                (visibleTech) => visibleTech.technologyType === 'KNOW'
                              )?.length -
                                1
                                ? ''
                                : ','
                            } `
                        )}
                    </span>
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {optionsCollapsed
        ?.filter((optionsCollapsed) => optionsCollapsed.isVisible)
        .map((options, index) => (
          <Collapsed
            collapsedSelected={collapsedSelected}
            setCollapsedSelected={setCollapsedSelected}
            index={index}
            options={options}
            collapsedEnabled={options.collapsedEnabled}
            containerClassname={options.containerClassname}
            titleClassnames={options.titleClassnames}
            maxHeight={'30rem'}
          />
        ))}
    </div>
  );
};

export default InvitedProfileStages;
