import CheckIcon from '../../assets/modal/Check';
import PointLeftFinger from '../../assets/modal/PointLeftFinger';

export const optionsMatch = (optionsToMatch, submittedOptions) => {
  let optionsMatch = false;
  optionsToMatch?.forEach((acceptedOptions) => {
    const acceptedOptionsAreIncluded = acceptedOptions?.options?.every((i) =>
      submittedOptions?.includes(i)
    );
    const selectedOptionsAmountMatchs =
      acceptedOptions?.options?.length === submittedOptions?.length;
    if (acceptedOptionsAreIncluded && selectedOptionsAmountMatchs) {
      optionsMatch = true;
    }
  });
  return optionsMatch;
};

export const optionIsAccepted = (optionId, submission) => {
  let response = optionsMatch(submission?.acceptedOptions.filter((x) => x.weight > 0) ?? [], [
    optionId,
  ]);
  if (submission?.hasGlobalScore) {
    const acceptedOptions =
      submission?.acceptedOptions.sort((a, b) => b.weight - a.weight)[0]?.options ?? [];
    response = acceptedOptions.includes(optionId);
  }

  return response;
};

export const optionIcon = (option, populatedTests, submission, showResult) => {
  let response = <></>;
  const isBestOption = submission?.acceptedOptions
    .sort((a, b) => b.weight - a.weight)[0]
    .options.includes(option.id);

  if (optionIsAccepted(option?.id, submission) && isBestOption) {
    response = <CheckIcon />;
  } else if (showResult) {
    const isSubmitted = submission?.submittedOptions.includes(option?.id);
    if (isSubmitted) {
      response = <PointLeftFinger />;
    }
  }

  return response;
};
