import { alkymindClient } from '../alkymindClient';
import { alkymetricsClient } from '../alkymetricsClient';
import { HiredStatus } from '../../constants/enums';
import { invertMatrix } from '../../utils/arrayUtils';
import { getSearchParams } from '../../utils/api/searchParams';

export const getProfilesFilters = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/users/filters`);
    return data.filters;
  } catch (error) {
    return {};
  }
};

export const getGoogleValidAccount = async (email) =>
  await alkymetricsClient.get(`/partner/resetPassword/${email}`);

export const getProfiles = async (qs = {}, onlyAvailable) => {
  const { data } = await alkymetricsClient.get(`/users`, {
    params: {
      ...qs,
      ...(onlyAvailable ? { status: HiredStatus.AVAILABLE } : {}),
      limit: '24',
    },
  });
  const profiles = data.collection.map((profile) => ({
    ...profile,
    groups: profile?.groups?.length
      ? profile?.groups?.sort((a, b) => (a?.group?.startDate < b?.group?.startDate ? 1 : -1))
      : [],
  }));
  return { ...data, collection: profiles };
};

export const getRecruitSessionStageActive = async (workspaceIds) => {
  const { data } = await alkymetricsClient.get(`/recruitSession/workspacesRecruitSessions`, {
    params: {
      workspaceIds,
      offset: 0,
      limit: '200',
    },
  });
  const profiles = data.collection.map((recruitSession) => ({
    name: recruitSession.name,
    id: recruitSession.id,
    description: recruitSession.description,
  }));
  return { ...data, collection: profiles };
};

export const getProfile = async (id) => {
  const { data } = await alkymetricsClient.get(`/users/${id}`);
  return {
    ...data,
    groups: data?.groups?.length
      ? data?.groups?.sort((a, b) => (a?.group?.startDate < b?.group?.startDate ? 1 : -1))
      : [],
  };
};
export const getProfileName = async (id, token = null) => {
  const { data } = await alkymetricsClient.get(
    `/users/${id}/name`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}
  );
  return data;
};

export const getProfileCommunications = async (id, recruitSessionStageId) => {
  const { data } = await alkymetricsClient.get(
    `/users/${id}/communications?recruitSessionStageId=${recruitSessionStageId}`
  );
  return data;
};

export const getProfileEvents = async (id, recruitSessionStageId) => {
  const { data } = await alkymetricsClient.get(
    `/users/${id}/events?recruitSessionStageId=${recruitSessionStageId}`
  );
  return data;
};

export const getProgress = async (id) => {
  try {
    const { data } = await alkymindClient.get(`profiles/${id}/progress`);
    return data;
  } catch (error) {
    return { error };
  }
};
export const getProfileSkills = async (id) => {
  try {
    const { data } = await alkymindClient.get(`profiles/byId/${id}`);
    return data;
  } catch (error) {
    return { error };
  }
};

export const getAlkymerAdvance = async (id, jobreadyId) => {
  try {
    const { data } = await alkymindClient.get(`/profiles/${id}`, { params: { jobreadyId } });
    return data;
  } catch (err) {
    return { error: err };
  }
};

export const getAdvanceTechnology = async (id) => {
  try {
    const { data } = await alkymindClient.get(`/technology/${id}`);
    return data;
  } catch (err) {
    return undefined;
  }
};

export const getAllTechnologies = async () => {
  try {
    const { data } = await alkymetricsClient.get('/technology');
    return data;
  } catch (err) {
    return undefined;
  }
};

export const getAllTechnologyTypes = async () => {
  try {
    const { data } = await alkymetricsClient.get('/technologyType');
    return data;
  } catch (err) {
    return undefined;
  }
};

export const getSomeProfiles = async (params) => {
  try {
    const { data } = await alkymetricsClient.get('/partner/profiles', {
      params,
    });
    return data;
  } catch (err) {
    return [];
  }
};

export const getSomeProfilesFilter = async () => {
  try {
    const { data } = await alkymetricsClient.get('/partner/profiles/filters');
    return data;
  } catch (err) {
    return {};
  }
};

export const saveFavoriteProfile = async (id) => {
  const { data } = await alkymetricsClient.post('/partner/profiles/favorite', [{ profileId: id }]);
  return data;
};

export const getFavoriteProfiles = async () => {
  const { data } = await alkymetricsClient.get('/partner/profiles/favorite');
  return data.map((relation) => relation.profile);
};

export const deleteFavoriteProfile = async (id) => {
  const data = await alkymetricsClient.delete(`/partner/profiles/favorite/${id}`);
  return data;
};

export const getNews = async (recomendationLimit) => {
  const { data } = await alkymetricsClient.get('/partner/news', {
    params: {
      limitRecomendation: recomendationLimit,
    },
  });
  return data;
};

export const getTopByTechnology = async (id) => {
  const { data } = await alkymetricsClient.get(`/users/top/technology/${id}`);
  return data;
};

export const getLastEmail = async (recruitSessionStageId = '') => {
  const { data } = await alkymetricsClient.get(`/customEmail/last/${recruitSessionStageId}`);
  return data;
};

export const getCustomEmailsAll = async (filters) => {
  const { data } = await alkymetricsClient.get(`/customEmail/withEvents`, { params: filters });
  return data;
};

export const restartCustomEmail = async (id) => {
  const { data } = await alkymetricsClient.get(`/customEmail/restart/${id}`);
  return data;
};

export const getTemplateById = async (id) => {
  const { data } = await alkymetricsClient.get(`/email/template/${id}`);
  return data;
};
export const getTemplateByDefault = async () => {
  const { data } = await alkymetricsClient.get(`/customEmail/template/default`);
  return data;
};

export const saveTemplateEmail = async (
  template,
  subject = '',
  recruitSessionStageId = null,
  buttonText,
  customTemplate,
  currentWorkspace = null,
  htmlTranslate = { es: { HTML: '', SUBJECT: '' } },
  isNew = false
) => {
  const request = customTemplate
    ? {
        html: '{{HTML}}',
        subject: 'SUBJECT',
        recruitSessionStageId: null,
        buttonText,
        eventType: customTemplate?.eventType,
        isEditable: true,
        workspaceId: currentWorkspace,
        translations: htmlTranslate,
        title: customTemplate?.title,
        isNew,
        id: customTemplate?.id,
      }
    : {
        html: template,
        subject,
        recruitSessionStageId,
        buttonText,
        workspaceId: currentWorkspace,
      };
  const { data } = await alkymetricsClient.post('/customEmail', request);
  return data;
};

export const getInvitedProfile = async (recruitSessionId, profileId) => {
  const { data } = await alkymetricsClient.get(
    `/recruitSession/${recruitSessionId}/profiles/${profileId}`
  );
  return data;
};

export const getCommunicationsAssociated = async (assessmentInvitationId) => {
  const { data } = await alkymetricsClient.get(
    `/assessmentInvitation/${assessmentInvitationId}/communications`
  );
  return data;
};

export async function uploadImagePartner(image) {
  const { data } = await alkymetricsClient.post(`/partner/me/picture`, image, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

/*   try {
  const { data } = await alkymetricsClient.get(`/assessmentInvitation/filters/${id}`);
  return data.filters;
} catch (error) {
  return {};
} */
export const getInvitationsFilters = async () => ({
  amountOfEvents: {
    min: 1,
    max: 63,
  },
  academic_level: [],
  sex: [
    {
      id: '2',
      name: 'Female',
    },
    {
      id: '1',
      name: 'Male',
    },
    {
      id: '3',
      name: 'No_Specify',
    },
    {
      id: '5',
      name: 'Non_Binary',
    },
  ],
  locations: [
    { id: 1, name: 'Argentina' },
    { id: 10, name: 'Colombia' },
    { id: 9, name: 'Uruguay' },
    { id: 7, name: 'Chile' },
    { id: 4, name: 'México' },
    { id: 5, name: 'Perú' },
    { id: 67, name: 'Ecuador' },
    { id: 3, name: 'Venezuela' },
    { id: 55, name: 'Costa Rica' },
    { id: 2, name: 'Bolivia' },
    { id: 36, name: 'Brasil' },
  ],
  states: [],
  languages: [
    {
      id: '1',
      name: 'SPANISH',
    },
    {
      id: '3',
      name: 'ENGLISH',
    },
    {
      id: '7',
      name: 'ITALIAN',
    },
    {
      id: '10',
      name: 'RUSSIAN',
    },
    {
      id: '4',
      name: 'PORTUGUESE',
    },
    {
      id: '8',
      name: 'GERMAN',
    },
    {
      id: '9',
      name: 'JAPANESE',
    },
    {
      id: '6',
      name: 'FRENCH',
    },
  ],
  visible: [
    { id: 1, name: 'SHOW' },
    { id: 0, name: 'HIDE' },
  ],
});

export async function uploadImageProfile(image) {
  const { data } = await alkymetricsClient.post('/partner/picture', image, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export const submitContactSalesMail = async (filters) =>
  await alkymetricsClient.post('/email/partner/contact', {
    appliedFilters: filters,
  });

export const submitContactPlansSalesMail = async (planId) =>
  await alkymetricsClient.post(`/email/partner/contact/plan/${planId}`);

export const getTermsAndConditions = async (platform = 'PARTNERS') => {
  const { data } = await alkymetricsClient.get('/terms-and-conditions', {
    params: { platform },
  });
  return data;
};

export const getRecruitSessionStatsById = async (id) => {
  try {
    const { data } = await alkymetricsClient.get(`recruitSessionStage/${id}/stats`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getDuplicateInvitations = async (id, invitedCandidates) => {
  if (!invitedCandidates.length) return [];
  const { data } = await alkymetricsClient.post(`recruitSessionStage/duplicateInvitedCandidates`, {
    id,
    invitedCandidates,
  });
  return data;
};

export const getPlanByWorkspaceId = async (workspaceId) => {
  const { data } = await alkymetricsClient.get(`/workspace/${workspaceId}/plan`);
  return data;
};

export const getPlanFeatures = async (planId) => {
  const { data } = await alkymetricsClient.get(`/plan/${planId}/features`);
  return data;
};

export const getPlan = async (planId) => {
  const data = await alkymetricsClient.get(`/plan/${planId}`);
  return data?.data;
};

export const getCandidatePdfExport = async (recruitSessionId, profileId) => {
  const { data } = await alkymetricsClient.get(
    `/recruitSession/report/${recruitSessionId}/profiles/${profileId}`,
    {
      responseType: 'blob',
    }
  );
  return data;
};

export const getCandidateQualitativePdfExport = async (
  recruitSessionId,
  profileId,
  testgroupId = null
) => {
  const response = await alkymetricsClient.get(
    `/recruitSession/qualitativeReport/${recruitSessionId}/profiles/${profileId}`,
    {
      responseType: 'json',
      params: {
        testgroupId,
      },
    }
  );
  const { data } = response;
  return data;
};
export const getCandidateQualitativeIPVPdfExport = async (
  recruitSessionId,
  profileId,
  partnerId
) => {
  const { data } = await alkymetricsClient.get(
    `/pdf/ipvReport/${recruitSessionId}/profileId/${profileId}`,
    {
      responseType: 'blob',
      params: {
        partnerId,
      },
    }
  );
  return data;
};

export const getPsychotechnicExportReport = async (screeningInstanceId = '') => {
  const { data } = await alkymetricsClient.get(`/pdf/psychotechnic/${screeningInstanceId}`, {
    responseType: 'blob',
  });
  return data;
};

export const getRecruitSessionStageLocationStats = async (stageId) => {
  const { data } = await alkymetricsClient.get(`/recruitSessionStage/${stageId}/locationStats`);
  return data;
};

export const getRecruitSessionCountryStats = async (stageId, countryId) => {
  const { data } = await alkymetricsClient.get(
    `/recruitSessionStage/${stageId}/countryStats/${countryId}`
  );
  return data;
};

export const getRecruitSessionAgeAndGendersMetricsById = async (id) => {
  try {
    const { data } = await alkymetricsClient.get(`/recruitSessionStage/${id}/stats/age-gender`);
    const result = {
      ...data,
      columns: data.rows,
      rows: data.columns,
      matrix: invertMatrix(data.matrix),
    };
    return result;
  } catch (error) {
    return null;
  }
};

export const getPartnersByWorkspaceId = async (workspaceId) => {
  try {
    const { data } = await alkymetricsClient.get(`/workspace/users/${workspaceId}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const modifyWorkspaceAsync = async (workspaceId, workspaceData) => {
  try {
    const { data } = await alkymetricsClient.patch(`/workspace/${workspaceId}`, workspaceData);
    return data;
  } catch (error) {
    return [];
  }
};

export const uploadWorkspaceIconAsync = async (workspaceId, file) => {
  try {
    const { data } = await alkymetricsClient.post(`/workspace/${workspaceId}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getPlans = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/plan?getPaymentPreferenceId=true`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getFeatures = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/features`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getStudyAreas = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/studyArea`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCountries = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/country`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadJobFileDescription = async (fileFormData) => {
  try {
    const { data } = await alkymetricsClient.post('/jobs/file', fileFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postNewJobPost = async (jsonJobPostData) => {
  try {
    const { data } = await alkymetricsClient.post('/jobs', jsonJobPostData);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getJobPost = async (id) => {
  try {
    const { data } = await alkymetricsClient.get(`/jobs/${id}`);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getJobs = async (filters) => {
  try {
    const params = getSearchParams(filters);
    const { data } = await alkymetricsClient.get(`/jobs?${params.toString()}`);
    return data;
  } catch (error) {
    return [];
  }
};
export const deleteJob = (id) => alkymetricsClient.delete(`/jobs/${id}`);

export const getJobsFiltersData = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/jobs/filters`);
    return data;
  } catch (error) {
    return [];
  }
};

export const patchEditJobPost = async (jsonJobPostData) => {
  try {
    const { data } = await alkymetricsClient.patch(
      `/jobs/${jsonJobPostData.hashId}`,
      jsonJobPostData
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const sendRecruitSessionStageSubmissionsExcelAsync = async (
  recruitSessionStageId,
  query,
  lang
) => {
  try {
    const { data } = await alkymetricsClient.get(
      `/recruitSessionStage/${recruitSessionStageId}/submissions/export`,
      {
        params: {
          ...query,
          exportLanguage: lang,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const createPreference = async () => {
  const data = await alkymetricsClient.post('/payments/preference');
  return data?.data;
};

export const updatePreApprovalReference = async (preapproval_id) => {
  const param = `?preapproval_id=${preapproval_id}`;
  const data = await alkymetricsClient.put(`/payments/reference${param}`);
  return data?.data;
};
export const getPaymentStatus = async (id) => {
  const data = await alkymetricsClient.get(`/payments/${id}/status`);
  return data?.data;
};
export const moveScreeningToWorkspace = async (rssId, screeningData) => {
  const { data } = await alkymetricsClient.post(
    `/recruitSessionStage/transfer/${rssId}`,
    screeningData
  );
  return data;
};

export const getInvitationsAndChallenges = async (profileId, partnerId) => {
  const { data } = await alkymetricsClient.get(
    `/assessmentInvitation/assessmentInvitationsAndChallenges/${profileId}`,
    {
      params: {
        partnerId,
      },
    }
  );
  return data;
};
