import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import {
  getCustomSearch,
  passStageInBatch,
  sendInvitationEmailInBatch,
  updateScreeningCandidatesBatch,
  updateRecruitSession,
  getScreeningInvitationsStatsCount,
} from '../API/services/screeningService';
import { t } from '../i18n/i18n';
import {
  setStageCandidates,
  setEmailsData,
  setSelectedSearch,
  setNewSearchData,
  setSendEmailFrom,
  setSubjectEmail,
  setSearchWasAlreadyCreated,
} from '../redux/actions/screeningActions';
import ModalCustom from '../components/ModalCustom/ModalCustom';
import {
  getDuplicateInvitations,
  getRecruitSessionStageActive,
  saveTemplateEmail,
  sendRecruitSessionStageSubmissionsExcelAsync,
} from '../API/services/alkymersService';
import Modal from '../components/Modal/Modal';
import Spinner from '../components/spinner/Spinner';
import FormInputSearch from '../components/FormInputSearch/FormInputSearch';
import { CREATE_EVALUATION, CREATE_TEMPLATE, SCREENING_SEARCHES } from '../constants/routes';

const inHtmlTag = ({ tag, className, content }) =>
  `<${tag} class="${className}">${content}</${tag}>`;

const useTableBatchAction = (recruitSessionStageId = null) => {
  const history = useHistory();
  const [selected, setSelected] = useState({});
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [modalStageVisible, setModalStageVisible] = useState(false);
  const [modifyVisibilitity, setModifyVisibilitity] = useState(false);
  const [modalModifyStageVisible, setModalModifyStageVisible] = useState(false);
  const [showCertificateDownloadModal, setShowCertificateDownloadModal] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [modalPassStageVisible, setModalPassStageVisible] = useState(false);
  const [modalSendMailVisible, setModalSendMailVisible] = useState(false);
  const [showModalSelectEvaluation, setShowModalSelectEvaluation] = useState(false);
  const [modalSendMailSubmissionsReportVisible, setModalSendMailSubmissionsReportVisible] =
    useState(false);
  const [isLoadingSendMailSubmissionReport, setIsLoadingSendMailSubmissionReport] = useState(false);
  const [modalEditMailVisible, setModalEditMailVisible] = useState(false);
  const [editMailVisible, setEditMailVisible] = useState(false);
  const [stageToUpdateValue, setStageToUpdateValue] = useState(null);
  const [profilesToModify, setProfilesToModify] = useState([]);
  const [profilesToInvite, setProfilesToInvite] = useState([]);
  const [passStageToUpdateValue, setPassStageToUpdateValue] = useState(null);
  const [customTemplateId, setCustomTemplateId] = useState(null);
  const [recruitSessionStages, setRecruitSessionStages] = useState([]);
  const [isLoadingRecruitSessionStages, setIsLoadingRecruitSessionStages] = useState(false);
  const [searchRecruitSessionStages, setSearchRecruitSessionStages] = useState('');
  const [recruitSessionStagesFiltered, setRecruitSessionStagesFiltered] = useState([]);

  const { stageCandidates, searchWasAlreadyCreated, searchInvitations, selectedSearch } =
    useSelector((state) => state.screenings);
  const { query } = useSelector((state) => state.profiles);
  const languageKey = useSelector((store) => store.ux.language);
  const { currentWorkspace } = useSelector((state) => state.auth);
  const { newSearch } = useSelector((state) => state.screenings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchRecruitSessionStages.length > 2) {
      const filterData = recruitSessionStages.filter((recruit) =>
        recruit.name.toLowerCase().includes(searchRecruitSessionStages.toLowerCase())
      );
      setRecruitSessionStagesFiltered(filterData);
    } else {
      setRecruitSessionStagesFiltered(recruitSessionStages);
    }
  }, [searchRecruitSessionStages, recruitSessionStages]);

  const toggleSelectedId = (id) => {
    if (selected[id]) {
      setAllSelected(false);
      setSelected((state) => ({ ...state, [id]: false }));
    } else {
      setSelected((state) => ({ ...state, [id]: true }));
    }
  };
  const selectAllIds = (ids) => {
    ids.forEach((id) => setSelected((state) => ({ ...state, [id]: true })));
  };

  const deselectAll = () => {
    setSelected({});
    setAllSelected(false);
  };

  const closeStageModal = () => {
    setModalStageVisible(false);
    setTimeout(() => {
      setStageToUpdateValue(null);
    }, 1000);
  };

  const closeModifyStageModal = () => {
    setModalModifyStageVisible(false);
    setTimeout(() => {
      setProfilesToModify([]);
    }, 1000);
  };

  const startModifyStageConfirmation = (value, flagModifyVisibility, flagCreateEvaluation) => {
    setModifyVisibilitity(flagModifyVisibility);
    setShowModalSelectEvaluation(flagCreateEvaluation);
    let selectedIds = [];
    if (value) {
      setProfilesToModify(value);
    } else {
      selectedIds = Object.keys(
        Object.fromEntries(Object.entries(selected).filter(([_, val]) => val))
      );
      setProfilesToModify(
        stageCandidates.collection.filter((candidate) =>
          selectedIds.includes(candidate.invitationId)
        )
      );
    }
    setModalModifyStageVisible(true);
  };

  const closePassStageModal = () => {
    setModalPassStageVisible(false);
    setStageToUpdateValue(null);
  };

  const startStageConfirmation = (value) => {
    setModalStageVisible(true);
    setStageToUpdateValue(value);
  };

  const startPassStageConfirmation = (value) => {
    setModalPassStageVisible(true);
    setPassStageToUpdateValue(value);
  };

  const closeEditMailModal = () => {
    setModalEditMailVisible(false);
  };

  const closeSendMailModal = () => {
    setModalSendMailVisible(false);
  };
  const closeSendMailSubmissionsReportModal = () => {
    setModalSendMailSubmissionsReportVisible(false);
  };

  const startEditMailConfirmation = () => {
    const mailSentProfiles = stageCandidates?.collection?.filter(
      (candidate) => selected[candidate.id] && candidate.mailSent
    );
    if (mailSentProfiles.length) {
      dispatch(setEmailsData(mailSentProfiles));
      setModalEditMailVisible(true);
    } else {
      setEditMailVisible(true);
    }
  };

  const startSendMailConfirmation = (templateId) => {
    setCustomTemplateId(templateId);
    setModalSendMailVisible(true);
  };

  const handleSendEmail = async (value) => {
    try {
      const response = await saveTemplateEmail(value);
      startSendMailConfirmation(response.id);
    } catch (e) {
      setEditMailVisible(false);
      notification.open({
        message: t('SEARCH_INVITATION_EMAIL_TEMPLATE_ERROR'),
        type: 'error',
      });
    }
  };

  const startEditMail = () => {
    setModalEditMailVisible(false);
    setEditMailVisible(true);
  };

  const updateStageSingleCandidate = async (newStatus, id) => {
    const body = {
      ids: [id],
      processStatus: newStatus,
    };
    if (
      newStatus &&
      stageCandidates?.collection?.find((item) => item.invitationId === id)?.processStatus !==
        newStatus
    ) {
      const updated = await updateScreeningCandidatesBatch(body);
      if (updated) {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_SUCCESS_SINGLE'),
          type: 'success',
        });
        dispatch(
          setStageCandidates({
            ...stageCandidates,
            collection: stageCandidates?.collection?.map((screening) => ({
              ...screening,
              ...(screening?.invitationId === id ? { processStatus: body.processStatus } : {}),
            })),
          })
        );
        deselectAll();
      } else {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_ERROR'),
          type: 'error',
        });
      }
    }
  };

  const sendInvitationEmails = async () => {
    const body = {
      invitationsIds: Object.keys(selected),
      emailTemplate: { customEmailId: customTemplateId },
      sendToAll: true,
    };
    const updated = await sendInvitationEmailInBatch(body);
    if (updated) {
      notification.open({
        message: t('SEARCH_EMAIL_INVITATION_SUCCESS'),
        type: 'success',
      });
      dispatch(
        setStageCandidates({
          ...stageCandidates,
          collection: stageCandidates?.collection?.map((screening) => ({
            ...screening,
            ...(selected[screening.invitationId] ? { emailSent: true } : {}),
          })),
        })
      );
    } else {
      notification.open({
        message: t('SEARCH_EMAIL_INVITATION_ERROR'),
        type: 'error',
      });
    }
    deselectAll();
    setEditMailVisible(false);
    setModalSendMailVisible(false);
  };

  const sendSubmissionsReportEmail = async () => {
    try {
      setIsLoadingSendMailSubmissionReport(true);
      await sendRecruitSessionStageSubmissionsExcelAsync(recruitSessionStageId, query, languageKey);
      notification.open({
        message: t('SEARCH_EMAIL_INVITATION_SUCCESS'),
        type: 'success',
      });
      setModalSendMailSubmissionsReportVisible(false);
    } catch (error) {
      notification.open({
        message: t('SEARCH_EMAIL_INVITATION_ERROR'),
        type: 'error',
      });
    } finally {
      setIsLoadingSendMailSubmissionReport(false);
    }
  };

  const updateStagesBatchCandidates = async () => {
    const selectedToUpdate = selected;
    const body = {
      processStatus: stageToUpdateValue,
      allCandidates: allSelected,
      filters: { stage: selectedSearch.stages[0].id, ...query },
    };
    closeStageModal();
    const idsArray = [];
    for (
      let i = 0;
      Object.keys(selected).length > i;
      i += Number(process.env.RAZZLE_RUNTIME_EMAIL_BATCH_SIZE)
    ) {
      idsArray.push(
        Object.keys(selected).slice(i, i + Number(process.env.RAZZLE_RUNTIME_EMAIL_BATCH_SIZE))
      );
    }

    if (allSelected) {
      await updateScreeningCandidatesBatch({ ...body, ids: [] })
        .then(() => {
          notification.open({
            message: t('SCREENING_PROCESS_STATUS_MODIFY_SUCCESS'),
            type: 'success',
          });
          dispatch(
            setStageCandidates({
              ...stageCandidates,
              collection: stageCandidates?.collection?.map((screening) => ({
                ...screening,
                ...(selectedToUpdate[screening.invitationId]
                  ? { processStatus: body.processStatus }
                  : {}),
              })),
            })
          );
        })
        .catch(() => {
          notification.open({
            message: t('SCREENING_PROCESS_STATUS_MODIFY_ERROR'),
            type: 'error',
          });
        });
      return;
    }

    Promise.all(
      idsArray.map(async (items) => {
        await updateScreeningCandidatesBatch({
          ...body,
          ids: items,
        });
      })
    )
      .then(() => {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_SUCCESS'),
          type: 'success',
        });
        dispatch(
          setStageCandidates({
            ...stageCandidates,
            collection: stageCandidates?.collection?.map((screening) => ({
              ...screening,
              ...(selectedToUpdate[screening.invitationId]
                ? { processStatus: body.processStatus }
                : {}),
            })),
          })
        );
      })
      .catch(() => {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_ERROR'),
          type: 'error',
        });
      });
  };

  const { recruitSessionId } = useParams();

  useEffect(() => {
    if (!Object.keys(selectedSearch).length && recruitSessionId) {
      getCustomSearch(recruitSessionId).then((res) => {
        dispatch(setSelectedSearch(res));
      });
    }
  }, []);

  const deleteCandidatesFromStageBatch = async () => {
    const profilesDeleteables = profilesToModify.filter(
      (item) => !(item.startDate || item.endDate)
    );

    const body = {
      deletedByPartner: true,
      allCandidates: allSelected,
      filters: { stage: selectedSearch.stages[0].id, ...query },
      toggleVisibility: false,
    };
    closeModifyStageModal();
    const idsArray = profilesDeleteables.map((item) => item.invitationId ?? item.id);

    if (allSelected) {
      await updateScreeningCandidatesBatch({ ...body, ids: [] })
        .then(() => {
          notification.open({
            message: t('SCREENING_DELETE_CANDIDATES_SUCCESS'),
            type: 'success',
          });
          dispatch(
            setStageCandidates({
              ...stageCandidates,
              collection: stageCandidates?.collection?.filter(
                (screening) => !idsArray.includes(screening.invitationId)
              ),
            })
          );
          getScreeningInvitationsStatsCount(selectedSearch.stages?.[0].id).then((res) => {
            dispatch(setSelectedSearch({ ...selectedSearch, stats: res }));
          });
          setQuantity(0);
        })
        .catch(() => {
          notification.open({
            message: t('SCREENING_DELETE_CANDIDATES_ERROR'),
            type: 'error',
          });
        });
      return;
    }

    if (idsArray.length > 0) {
      await updateScreeningCandidatesBatch({
        ...body,
        ids: idsArray,
      })
        .then(() => {
          notification.open({
            message: t('SCREENING_DELETE_CANDIDATES_SUCCESS'),
            type: 'success',
          });
          stageCandidates.pagination.total -= idsArray.length;
          dispatch(
            setStageCandidates({
              ...stageCandidates,
              collection: stageCandidates?.collection?.filter(
                (screening) => !idsArray.includes(screening.invitationId)
              ),
            })
          );
          getScreeningInvitationsStatsCount(selectedSearch.stages?.[0].id).then((res) => {
            dispatch(setSelectedSearch({ ...selectedSearch, stats: res }));
          });
          setQuantity(0);
          setSelected({});
          if (/profiles/.test(history.location.pathname)) {
            history.push(`/search/${selectedSearch.id}`);
          }
        })
        .catch(() => {
          notification.open({
            message: t('SCREENING_DELETE_CANDIDATES_ERROR'),
            type: 'error',
          });
        });
    }
  };

  const toggleCandidatesVisibilityFromStageBatch = async () => {
    const body = {
      deletedByPartner: true,
      allCandidates: allSelected,
      filters: { stage: selectedSearch.stages[0].id, ...query },
      toggleVisibility: true,
    };
    closeModifyStageModal();
    const idsArray = profilesToModify.map((item) => item.invitationId);

    if (allSelected) {
      await updateScreeningCandidatesBatch({ ...body, ids: [] })
        .then(() => {
          notification.open({
            message: t('SCREENING_MODIFY_CANDIDATES_SUCCESS'),
            type: 'success',
          });
          const stageCandidatesModified = { ...stageCandidates };

          stageCandidatesModified.collection.forEach((candidate) => {
            candidate.visible = !candidate.visible;
          });
          dispatch(setStageCandidates(stageCandidatesModified));
          setQuantity(0);
        })
        .catch(() => {
          notification.open({
            message: t('SCREENING_MODIFY_CANDIDATES_ERROR'),
            type: 'error',
          });
        });
      return;
    }

    if (idsArray.length > 0) {
      await updateScreeningCandidatesBatch({
        ...body,
        ids: idsArray,
      })
        .then(() => {
          notification.open({
            message: t('SCREENING_MODIFY_CANDIDATES_SUCCESS'),
            type: 'success',
          });

          const stageCandidatesModified = { ...stageCandidates };

          stageCandidatesModified.collection.forEach((candidate) => {
            if (idsArray.includes(candidate.invitationId)) {
              candidate.visible = !candidate.visible;
            }
          });
          dispatch(setStageCandidates(stageCandidatesModified));

          setQuantity(0);
          setSelected({});
          if (/profiles/.test(history.location.pathname)) {
            history.push(`/search/${selectedSearch.id}`);
          }
        })
        .catch(() => {
          notification.open({
            message: t('SCREENING_MODIFY_CANDIDATES_ERROR'),
            type: 'error',
          });
        });
    }
  };

  const passStageBatch = async () => {
    const body = {
      invitationsIds: Object.keys(selected),
      screeningName: passStageToUpdateValue.screeningName,
      recruitSessionStageId: passStageToUpdateValue.stageId,
    };
    closePassStageModal();
    const updated = await passStageInBatch(body);
    if (updated) {
      notification.open({
        message: t('SCREENING_PASS_STAGE_SUCCESS'),
        type: 'success',
      });
    } else {
      notification.open({
        message: t('SCREENING_PASS_STAGE_ERROR'),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    setQuantity(Object.values(selected).filter((value) => value).length);
  }, [selected]);

  useEffect(() => {
    if (allSelected && stageCandidates.collection.length) {
      selectAllIds(stageCandidates.collection.map((invitation) => invitation.invitationId));
    }
  }, [stageCandidates]);

  const buildModalStageContent = () =>
    t(
      allSelected
        ? 'SCREENING_PROCESS_STATUS_MODIFY_MODAL_TEXT_ALL_CANDIDATES'
        : 'SCREENING_PROCESS_STATUS_MODIFY_MODAL_TEXT'
    )
      .replace(
        '{quantity}',
        inHtmlTag({
          tag: 'b',
          className: '',
          content: allSelected ? t('MODIFY_STATUS_ALL_CANDIDATES') : quantity,
        })
      )
      .replace(
        '{persons}',
        inHtmlTag({
          tag: 'b',
          className: '',
          content:
            quantity === 1
              ? t('SCREENING_PROCESS_STATUS_MODIFY_PERSON')
              : t('SCREENING_PROCESS_STATUS_MODIFY_PERSONS'),
        })
      )
      .replace(
        '{processStatus}',
        inHtmlTag({
          tag: 'b',
          className: '',
          content: t(`SCREENING_INVITATIONS_PROCESS_STATUS_${stageToUpdateValue}`, true),
        })
      );

  const buildModifyModalStageTitle = () => {
    let title = '';
    if (!modifyVisibilitity) {
      title = t('DELETE_CANDIDATE_TITLE');

      const profilesNotDeleteables = profilesToModify.filter(
        (item) => item.startDate || item.endDate
      );

      if (profilesNotDeleteables.length > 0) {
        title = t('DELETE_CANDIDATE_ERROR_SINGULAR');
      }

      if (profilesNotDeleteables.length > 1 && profilesToModify.length > 1) {
        title = t('DELETE_CANDIDATE_ERROR_PLURAL');
      }

      if (profilesToModify?.length > 1 && !profilesNotDeleteables.length) {
        title = t('DELETE_CANDIDATE_TITLE_PLURAL').replace('{candidates}', profilesToModify.length);
      }
    } else {
      title = t('MODIFY_CANDIDATE_TITLE');
    }

    return title;
  };

  const buildModifyModalStageContent = () => {
    let content = '';
    if (!modifyVisibilitity) {
      content = t('DELETE_CANDIDATE_CONTENT');

      if (profilesToModify?.length > 1) {
        content = t('DELETE_CANDIDATE_CONTENT_PLURAL');
      }

      const profilesNotDeleteables = profilesToModify.filter(
        (item) => item.startDate || item.endDate
      );

      if (profilesNotDeleteables.length > 1) {
        content = t('DELETE_CANDIDATE_ERROR_SUBTITLE_PLURAL');
      } else if (profilesNotDeleteables.length > 0) {
        content = t('DELETE_CANDIDATE_ERROR_SUBTITLE_SINGULAR');
      }
    } else {
      content = t('MODIFY_CANDIDATE_CONTENT');
    }

    return content;
  };

  const confirmModalStage = (
    <ModalCustom
      onCancel={closeStageModal}
      onCancelText={t('SCREENING_PROCESS_STATUS_MODIFY_CANCEL')}
      onOk={updateStagesBatchCandidates}
      onOkText={t('SCREENING_PROCESS_STATUS_MODIFY_CONFIRM')}
      setShow={setModalStageVisible}
      show={modalStageVisible}
      subtitle={
        <span
          className=""
          dangerouslySetInnerHTML={{
            __html: buildModalStageContent(),
          }}
        />
      }
    />
  );

  const defineModificationAction = () => {
    const flag = profilesToModify.filter((item) => item.startDate || item.endDate).length;
    if (!modifyVisibilitity) {
      flag ? closeModifyStageModal() : deleteCandidatesFromStageBatch();
    } else {
      toggleCandidatesVisibilityFromStageBatch();
    }
  };

  const confirmModifyModalStage = (
    <ModalCustom
      showCross
      onOk={() => defineModificationAction()}
      onOkText={
        modifyVisibilitity ? t('MODIFY_CANDIDATE_MODAL_AGREE') : t('DELETE_CANDIDATE_MODAL_AGREE')
      }
      setShow={setModalModifyStageVisible}
      show={modalModifyStageVisible}
      title={buildModifyModalStageTitle()}
      subtitle={
        <span
          className=""
          dangerouslySetInnerHTML={{
            __html: buildModifyModalStageContent(),
          }}
        />
      }
    />
  );

  const createAssessmentInvitationAction = async (body) => {
    const profileData = {};
    const keys = profilesToInvite.map(() => v4());
    profilesToInvite.forEach((row, index) => {
      profileData[keys[index]] = {
        email: row?.email,
        firstName: row?.firstName,
        lastName: row?.lastName,
      };
    });
    let profilesValidated = profileData;
    const emailsToValidate = profilesToInvite.map((profile) => profile?.email);
    let candidateRepeated = [];
    try {
      candidateRepeated = await getDuplicateInvitations(body?.id, emailsToValidate);
    } catch (e) {
      notification.open({
        message: t('ERROR_EVALUATION'),
        type: 'error',
      });
      return;
    }
    if (candidateRepeated?.length > 0) {
      notification.open({
        message: t('DUPLICATE_INVITATION_WARNING_NOTIFICATION'),
        description: candidateRepeated.join('\n'),
        type: 'warning',
      });
      profilesValidated = Object.entries(profileData)
        .filter(([_, value]) => !candidateRepeated.includes(value.email))
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    }

    if (Object.keys(profilesValidated).length > 0) {
      dispatch(setSendEmailFrom('edit'));
      const selectedSearch = {
        name: body?.name,
        stages: [
          {
            id: body?.id,
            levelId: body?.levelId,
            technologyId: body?.technologyId,
          },
        ],
      };
      dispatch(
        setNewSearchData({
          ...newSearch,
          ...selectedSearch,
          step: 4,
        })
      );
      dispatch(setSubjectEmail({ subjectEmail: '' }));
      dispatch(setSearchWasAlreadyCreated(true));
      history.push(CREATE_TEMPLATE);
      dispatch(setEmailsData(profilesValidated));
    }
  };

  const handleClickAddInvitations = (item) => {
    const body = {
      id: item?.id,
      levelId: item?.levelId,
      name: item?.name,
      technologyId: item?.technologyId,
    };
    createAssessmentInvitationAction(body);
  };

  const startAddingMemberToStageConfirmation = async (value) => {
    let selectedIds = [];
    if (value) {
      setProfilesToInvite(value);
    } else {
      selectedIds = Object.keys(
        Object.fromEntries(Object.entries(selected).filter(([_, val]) => val))
      );
      setProfilesToInvite(
        stageCandidates.collection.filter((candidate) =>
          selectedIds.includes(candidate.invitationId)
        )
      );
    }
    setShowModalSelectEvaluation(true);
    setIsLoadingRecruitSessionStages(true);
    try {
      const data = await getRecruitSessionStageActive(currentWorkspace);
      setRecruitSessionStages(data.collection);
      dispatch(
        setNewSearchData({
          name: '',
          stagesQuantity: 1,
          stages: [],
          step: 1,
        })
      );
    } catch (e) {
      notification.open({
        message: t('ERROR_LOOKING_EVALUATION_TRY_MANUALLY'),
        type: 'error',
      });
      setShowModalSelectEvaluation(false);
    }
    setIsLoadingRecruitSessionStages(false);
  };

  const modalSelectEvaluation = (
    <Modal
      show={showModalSelectEvaluation}
      minWidth={false}
      className="overflow-auto first-modal-close-btn w-25 h-50"
      closeBtnClassname="ml-3 mb-3"
      showCloseButton
      setShow={setShowModalSelectEvaluation}
    >
      <span className=" mx-5 font-weight-500 text-base text-center mb-3 w-80">
        {t('SELECT_EVALUATION_SHORTCUT_TITLE')}
      </span>
      {isLoadingRecruitSessionStages ? (
        <Spinner show />
      ) : (
        <>
          <div>
            <ul className="select-evaluation-dropdown position-absolute bg-white overflow-auto m-0 py-2">
              {recruitSessionStagesFiltered?.length > 0 ? (
                <>
                  <li>
                    <FormInputSearch
                      searchText={searchRecruitSessionStages}
                      setSearchText={setSearchRecruitSessionStages}
                      placeholder={t('EVALUATION_PLACEHOLDER')}
                      className="w-90 text-xs"
                    />
                  </li>
                  <div className="h-full">
                    {recruitSessionStagesFiltered.map((item, index) => (
                      <li key={item.id} className="py-1 px-2 w-100 cursor-pointer dropdown-option">
                        <button
                          className="dropdown-btn d-flex align-items-center border-0 px-2 w-100"
                          onClick={() => handleClickAddInvitations(item)}
                          type="button"
                        >
                          <div className="row no-gutters w-100 justify-content-start align-items-center recruit-session-stage-options">
                            <div className="col-2 text-xs text-left">{index}.</div>
                            <div className="col-10 text-xs text-left">
                              <span className="pl-1 ">{item.name}</span>
                            </div>
                          </div>
                        </button>
                      </li>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col items-center justify-center text-center h-full">
                    <span className=" font-weight-500 mx-2 text-base text-center mb-3 w-100">
                      {t('NON_EVALUATIONS_TO_SELECT_SHORTCUT')}
                    </span>
                    <button
                      data-testid="button-create-search"
                      className="mt-3  font-weight-bold border-0 text-xs text-white px-3 py-1 rounded-pill h-25 bg-maya-blue"
                      type="button"
                      onClick={() => {
                        TrackEvent('create-evaluation-dashboard');
                        history.push({
                          pathname: CREATE_EVALUATION,
                          state: {
                            from: SCREENING_SEARCHES,
                            fromName: 'MENU_ITEM_SEARCHES',
                          },
                        });
                      }}
                    >
                      {t('CREATE_SEARCH')}
                    </button>
                  </div>
                </>
              )}
            </ul>
          </div>
        </>
      )}
    </Modal>
  );

  const buildModalPassStageContent = () =>
    t('SCREENING_PASS_STAGE_MODIFY_MODAL_TEXT')
      .replace(
        '{quantity}',
        inHtmlTag({
          tag: 'b',
          className: '',
          content: quantity,
        })
      )
      .replace(
        '{persons}',
        inHtmlTag({
          tag: 'b',
          className: '',
          content:
            quantity === 1 ? t('SCREENING_PASS_STAGE_PERSON') : t('SCREENING_PASS_STAGE_PERSONS'),
        })
      );

  const confirmModalPassStage = (
    <ModalCustom
      onCancel={closePassStageModal}
      onCancelText={t('SCREENING_PASS_STAGE_CANCEL')}
      onOk={passStageBatch}
      onOkText={t('SCREENING_PASS_STAGE_CONFIRM')}
      setShow={setModalPassStageVisible}
      show={modalPassStageVisible}
      subtitle={
        <span
          className=""
          dangerouslySetInnerHTML={{
            __html: buildModalPassStageContent(),
          }}
        />
      }
    />
  );

  const handleAllowCertificateDownload = async (allowCertificateDownload) => {
    await updateRecruitSession(selectedSearch.id, { allowCertificateDownload })
      .then(() => {
        setTimeout(() => {
          dispatch(setSelectedSearch({ ...selectedSearch, allowCertificateDownload }));
        }, 700);
      })
      .catch(() => {
        notification.open({
          message: t('ALLOW_CERTIFICATE_DOWNLOAD_REQUEST_ERROR'),
          type: 'error',
        });
      })
      .finally(() => {
        setShowCertificateDownloadModal(false);
      });
  };

  const confirmModalEditMail = (
    <ModalCustom
      onCancel={closeEditMailModal}
      onCancelText={t('SCREENING_EDIT_MAIL_CANCEL')}
      onOk={startEditMail}
      onOkText={t('SCREENING_EDIT_MAIL_CONFIRM')}
      setShow={setModalEditMailVisible}
      show={modalEditMailVisible}
      subtitle={
        <>
          <span className="">{t('SCREENING_EDIT_MODAL_TEXT')}</span>
          <ul className="text-left mt-3 pl-3">
            {stageCandidates?.collection
              ?.filter((candidate) => selected[candidate.id] && candidate.mailSent)
              ?.map((profile) => (
                <li className=" text-xs">{profile.email}</li>
              ))}
          </ul>
        </>
      }
    />
  );

  const buildModalSendEmailContent = () =>
    t('SCREENING_SEND_EMAIL_MODAL_TEXT')
      .replace(
        '{quantity}',
        inHtmlTag({
          tag: 'b',
          className: '',
          content: quantity,
        })
      )
      .replace(
        '{persons}',
        inHtmlTag({
          tag: 'b',
          className: '',
          content:
            quantity === 1 ? t('SCREENING_PASS_STAGE_PERSON') : t('SCREENING_PASS_STAGE_PERSONS'),
        })
      );

  const confirmModalSendMail = (
    <ModalCustom
      onCancel={closeSendMailModal}
      onCancelText={t('SCREENING_SEND_MAIL_CANCEL')}
      onOk={sendInvitationEmails}
      onOkText={t('SCREENING_SEND_MAIL_CONFIRM')}
      setShow={setModalSendMailVisible}
      show={modalSendMailVisible}
      subtitle={
        <>
          <span
            className=""
            dangerouslySetInnerHTML={{
              __html: buildModalSendEmailContent(),
            }}
          />
        </>
      }
    />
  );
  const confirmModalSendMailSubmissionsReport = (
    <ModalCustom
      onCancel={closeSendMailSubmissionsReportModal}
      onCancelText={t('SCREENING_SEND_MAIL_CANCEL')}
      onOk={sendSubmissionsReportEmail}
      onOkText={t('SCREENING_SEND_MAIL_CONFIRM')}
      setShow={setModalSendMailSubmissionsReportVisible}
      show={modalSendMailSubmissionsReportVisible}
      title={<span className="">{t('SCREENING_SEND_MAIL_SUBMISSIONS_REPORT_TITLE')}</span>}
      subtitle={<span className="">{t('SCREENING_SEND_MAIL_SUBMISSIONS_REPORT_SUBTITLE')}</span>}
      isLoading={isLoadingSendMailSubmissionReport}
    />
  );
  const allowCertificateDownloadModal = (
    <ModalCustom
      onCancel={() => setShowCertificateDownloadModal(false)}
      onCancelText={t('ALLOW_CERTIFICATE_DOWNLOAD_MODAL_ON_CANCEL_TEXT')}
      onOk={() => handleAllowCertificateDownload(!selectedSearch.allowCertificateDownload)}
      classModal="allow-certificate-download-modal-width"
      subtitleClassName="px-0 px-lg-0"
      onOkText={
        selectedSearch.allowCertificateDownload
          ? t('ALLOW_CERTIFICATE_DOWNLOAD_MODAL_ON_OK_TEXT_DONT_ALLOW')
          : t('ALLOW_CERTIFICATE_DOWNLOAD_MODAL_ON_OK_TEXT_ALLOW')
      }
      setShow={setShowCertificateDownloadModal}
      show={showCertificateDownloadModal}
      title={
        selectedSearch.allowCertificateDownload
          ? t('ALLOW_CERTIFICATE_DOWNLOAD_MODAL_ALLOWED_TITLE')
          : t('ALLOW_CERTIFICATE_DOWNLOAD_MODAL_NOT_ALLOWED_TITLE')
      }
      subtitle={
        selectedSearch.allowCertificateDownload
          ? t('ALLOW_CERTIFICATE_DOWNLOAD_MODAL_ALLOWED_SUBTITLE')
          : t('ALLOW_CERTIFICATE_DOWNLOAD_MODAL_NOT_ALLOWED_SUBTITLE')
      }
    />
  );

  const updateDateLimitInvitation = async (limitDate, id) => {
    const body = {
      ids: [id],
      limitDate,
    };
    const updated = await updateScreeningCandidatesBatch(body);

    if (updated) {
      notification.open({
        message: t('SCREENING_UPDATE_LIMIT_DATE_SUCCESS'),
        type: 'success',
      });
      dispatch(
        setStageCandidates({
          ...stageCandidates,
          collection: stageCandidates?.collection?.map((screening) => ({
            ...screening,
            ...(selected[screening.invitationId] ? { limitDate } : {}),
          })),
        })
      );
    } else {
      notification.open({
        message: t('SCREENING_UPDATE_LIMIT_DATE_ERROR'),
        type: 'error',
      });
    }
  };

  return {
    selected,
    quantity,
    confirmModalStage,
    confirmModalPassStage,
    invitationsList: stageCandidates?.collection,
    totalInvitations: stageCandidates?.pagination?.total,
    confirmModalEditMail,
    editMailVisible,
    confirmModalSendMail,
    confirmModalSendMailSubmissionsReport,
    searchWasAlreadyCreated,
    searchInvitations,
    allSelected,
    showMoreFilters,
    showCertificateDownloadModal,
    allowCertificateDownloadModal,
    setSelected,
    toggleSelectedId,
    selectAllIds,
    deselectAll,
    startStageConfirmation,
    updateStageSingleCandidate,
    startPassStageConfirmation,
    startEditMailConfirmation,
    setEditMailVisible,
    handleSendEmail,
    setAllSelected,
    confirmModifyModalStage,
    startModifyStageConfirmation,
    setShowMoreFilters,
    setShowCertificateDownloadModal,
    setModifyVisibilitity,
    setModalSendMailSubmissionsReportVisible,
    startAddingMemberToStageConfirmation,
    modalSelectEvaluation,
    updateDateLimitInvitation,
    selected,
  };
};

export default useTableBatchAction;
